import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import {
  useCreateMemo,
  useDeleteMemo,
  useGetMemos,
  useUpdateMemo,
} from "hooks/queries/userAlphaNoteMemoQuery";

const MemoContainer = styled.div`
  position: fixed;
  right: ${(props) => (props.isOpen ? "0" : "-400px")};
  top: 50%;
  transform: translateY(-50%);
  width: 400px;
  height: 80vh;
  background: #ffffff;
  box-shadow: -2px 0 10px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
  display: flex;
  flex-direction: column;
  z-index: 1000;
`;

const ToggleButton = styled.button`
  font-size: 20px;
  font-weight: 500;
  font-family: Pretendard;
  position: absolute;
  left: -60px;
  top: 50%;
  transform: translateY(-50%);
  width: 60px;
  height: 100px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-right: none;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  &:hover {
    background: #f5f5f5;
  }
`;

const MemoList = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 16px;
`;

const MemoItem = styled.div`
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 8px;
  background: #f9fafc;
  border-radius: 4px;

  .memo-text {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 8px;
  }

  .memo-time {
    font-size: 12px;
    color: #666;
  }
`;

const InputWrapper = styled.form`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-bottom: 1px solid #e0e0e0;
  background: #fff;
`;

const MemoInput = styled.textarea`
  width: 100%;
  height: 80px;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  resize: none;
  font-size: 14px;
  margin-bottom: 8px;
  &:focus {
    outline: none;
    border-color: #4a90e2;
    background: #f9fafc;
  }
`;

const SubmitButton = styled.button`
  padding: 8px 16px;
  background: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  align-self: flex-end;

  &:hover {
    background: #357abd;
  }

  &:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
`;

const MemoItemActions = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 4px;
`;

const ActionButton = styled.button`
  padding: 4px 8px;
  font-size: 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: ${(props) => (props.isPrimary ? "#4a90e2" : "#e0e0e0")};
  color: ${(props) => (props.isPrimary ? "white" : "#333")};

  &:hover {
    background: ${(props) => (props.isPrimary ? "#357abd" : "#cccccc")};
  }
`;

const AlphaNoteMemo = ({ cuid, code }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newMemo, setNewMemo] = useState("");
  const [editingMemo, setEditingMemo] = useState(null);
  const listRef = useRef(null);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useGetMemos(
    cuid,
    code
  );

  const createMemoMutation = useCreateMemo();
  const updateMemoMutation = useUpdateMemo();
  const deleteMemoMutation = useDeleteMemo();

  const handleScroll = () => {
    if (!listRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = listRef.current;
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newMemo.trim()) return;

    if (editingMemo) {
      updateMemoMutation.mutate(
        {
          cuid,
          code,
          memo: newMemo,
          _id: editingMemo._id,
        },
        {
          onSuccess: () => {
            setNewMemo("");
            setEditingMemo(null);
          },
        }
      );
    } else {
      createMemoMutation.mutate(
        {
          cuid,
          code,
          memo: newMemo,
        },
        {
          onSuccess: () => {
            setNewMemo("");
          },
        }
      );
    }
  };

  const handleEdit = (memo) => {
    setEditingMemo(memo);
    setNewMemo(memo.memo);
  };

  const handleCancelEdit = () => {
    setEditingMemo(null);
    setNewMemo("");
  };

  const handleDelete = (memoId) => {
    if (window.confirm("메모를 삭제하시겠습니까?")) {
      deleteMemoMutation.mutate({
        _id: memoId,
        cuid,
        code,
      });
    }
  };

  return (
    <MemoContainer $isOpen={isOpen}>
      <ToggleButton onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? ">>" : "메모"}
      </ToggleButton>

      <InputWrapper onSubmit={handleSubmit}>
        <MemoInput
          value={newMemo}
          onChange={(e) => setNewMemo(e.target.value)}
          placeholder="메모를 입력하세요..."
        />
        <div
          style={{ display: "flex", gap: "8px", justifyContent: "flex-end" }}
        >
          {editingMemo && (
            <SubmitButton
              type="button"
              onClick={handleCancelEdit}
              style={{ background: "#e0e0e0", color: "#333" }}
            >
              취소
            </SubmitButton>
          )}
          <SubmitButton
            type="submit"
            disabled={
              !newMemo.trim() ||
              createMemoMutation.isLoading ||
              updateMemoMutation.isLoading
            }
          >
            {editingMemo ? "수정하기" : "작성하기"}
          </SubmitButton>
        </div>
      </InputWrapper>

      <MemoList ref={listRef} onScroll={handleScroll}>
        {data?.pages.map((page, i) => (
          <React.Fragment key={i}>
            {page.data.memos.map((memo) => (
              <MemoItem key={memo._id}>
                <div className="memo-text">{memo.memo}</div>
                <div className="memo-time">
                  {new Date(memo.time).toLocaleString()}
                </div>
                <MemoItemActions>
                  <ActionButton
                    onClick={() => handleEdit(memo)}
                    disabled={editingMemo?._id === memo._id}
                  >
                    수정
                  </ActionButton>
                  <ActionButton onClick={() => handleDelete(memo._id)}>
                    삭제
                  </ActionButton>
                </MemoItemActions>
              </MemoItem>
            ))}
          </React.Fragment>
        ))}
        {isFetchingNextPage && <div>Loading more...</div>}
      </MemoList>
    </MemoContainer>
  );
};

export default AlphaNoteMemo;
