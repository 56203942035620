import styled from "styled-components";
import DocumentImage from "../../assets/images/img_empty_document.png";
import { LinkOutIcon } from "./AlphaDetailMyNote";
import { useEffect, useState } from "react";
import LoginRequired from "pages/Login/LoginRequired";
import base64 from "base-64";
import { useNavigate } from "react-router-dom";

const DetailWrapper = styled.div`
  color: #141414;
  font-family: Pretendard;
  padding: 184px 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 260px;
  }
  .title-section {
    margin-top: 20px;
    font-family: Pretendard;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
    text-align: center;
    color: #121314;
  }
  .info {
    margin-top: 4px;
    margin-top: 20 px;
    font-family: Pretendard;
    color: #969799;
    font-size: 12px;
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .button {
    margin-top: 20px;
    margin-bottom: 20px;
    background: #ff3d6c;
    border-radius: 8px;
    font-family: Pretendard;
    font-size: 14px;
    font-weight: 600;
    line-height: 16.71px;
    text-align: center;

    color: #ffffff;
    width: 109px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;
const AlphaDetailEmptyNote = ({ setType = () => {} }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("access_token");

    //  check is token expired
    if (token) {
      const payload = JSON.parse(base64.decode(token.split(".")[1]));
      const currentTime = Date.now() / 1000;

      if (payload.exp < currentTime) {
        localStorage.removeItem("access_token");
      } else {
        setIsLoggedIn(true);
      }
    }
  }, [localStorage.getItem("access_token")]);

  return isLoggedIn ? (
    <DetailWrapper>
      <img src={DocumentImage} alt="document illustrator" />
      <div className="title-section">업로드한 매매일지가 없습니다.</div>
      <div
        className="button"
        onClick={() => {
          setType("setting");
        }}
      >
        매매일지 업로드
      </div>

      <div className="info" onClick={() => navigate("/trader")}>
        다른 트레이더 보러가기
        <LinkOutIcon fill={"#969799"} />
      </div>
      {/* 
      <div
        className="info"
        onClick={
          // pop up a new tab
          () => {
            window.open(
              "https://www.arpa.co.kr/a15cd364395f4ef18b1f0d8a7e2f3a89",
              "_blank"
            );
          }
        }
      >
        매매일지 업로드 방법 보러가기
        <LinkOutIcon fill={"#969799"} />
      </div> */}
    </DetailWrapper>
  ) : (
    <DetailWrapper>
      <LoginRequired />
    </DetailWrapper>
  );
};

export default AlphaDetailEmptyNote;
