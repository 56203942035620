import AlphaTraderCard from "components/AlphaTraderCard";
import TradeToggle from "components/TradeToggle";
import TraderLeagueTableSection from "components/TraderLeagueTableSection";
import { useAlphaRankQuery } from "hooks/queries/useAlphaTraderListQuery";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { LEAGUE_LABELS, TRADE_STYLE, TRADE_RECOMMEND } from "utils/consts";
import { useSelectedCompetition } from "utils/store";

const SectionWrapper = styled.div`
  margin-bottom: 30px;
`;

const Wrapper = styled.div`
  max-width: 1680px;
  margin: 0 auto;
  padding: 0 16px;

  @media (max-width: 980px) {
    padding: 0 12px;
  }
`;

const CardSection = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: 980px) {
    grid-template-columns: 1fr;
  }
`;

const DeckSection = styled.div`
  width: 100%;
  margin-bottom: 40px;
`;

const DeckTitle = styled.h2`
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 600;
  margin-top: -18px;
  margin-bottom: 12px;
  color: #333;
  text-align: left;

  @media (max-width: 980px) {
    font-size: 20px;
    margin-top: -12px;
    margin-bottom: 8px;
  }
`;

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: 980px) {
    grid-template-columns: 1fr;
  }
`;

const ToggleWrapper = styled.div`
  width: 100%;
  margin: 32px 0;

  @media (max-width: 980px) {
    margin: 24px 0;
  }
`;

const CardContainer = styled.div`
  /* CardWrapper의 고정 너비를 오버라이드 */
  .trader-card {
    width: 100% !important;
    max-width: 360px;
    margin: 0 auto;

    @media (max-width: 400px) {
      height: auto;
      min-height: 282px;
    }
  }
`;

const AlphaTraderRanking = ({ type = "league" }) => {
  const category = (() => {
    switch (type) {
      case "league":
        return LEAGUE_LABELS;
      case "style":
        return TRADE_STYLE;
      case "recommend":
        return TRADE_RECOMMEND;
      default:
        return [];
    }
  })();

  const [league, setLeague] = useState(category[0]?.type || "");
  const { selectedCompetition } = useSelectedCompetition((state) => state);

  const { data: rankerData, refetch } = useAlphaRankQuery({
    league,
    cid: selectedCompetition?.cid,
    type,
  });

  const handleCardClick = (id) => {
    window.open(`/trader/${id}`, "_blank");
  };

  const renderContent = () => {
    if (type === "recommend") {
      return (
        <>
          {rankerData?.map(
            (deck, deckIndex) =>
              deck.cards.length > 0 && (
                <DeckSection key={`deck-${deckIndex}`}>
                  <DeckTitle>{deck.title}</DeckTitle>
                  <CardsWrapper>
                    {deck.cards.slice(0, 3).map((data, index) => (
                      <CardContainer key={`${deckIndex}-${index}`}>
                        <AlphaTraderCard
                          type={type}
                          data={data}
                          index={index + 1}
                          onClick={() => handleCardClick(data?.cuid || "")}
                          updateIsLiked={() => refetch()}
                        />
                      </CardContainer>
                    ))}
                  </CardsWrapper>
                </DeckSection>
              )
          )}
        </>
      );
    }

    return (
      <>
        <CardSection>
          {rankerData &&
            rankerData[league].slice(0, 3).map((data, index) => (
              <CardContainer key={index}>
                <AlphaTraderCard
                  type={type}
                  data={data}
                  index={data?.sequence || index + 1}
                  onClick={() => handleCardClick(data?.cuid || "")}
                  updateIsLiked={() => refetch()}
                />
              </CardContainer>
            ))}
        </CardSection>
        <SectionWrapper>
          {rankerData && (
            <TraderLeagueTableSection
              type={type}
              dataList={rankerData[league].slice(3)}
              updateIsLiked={() => refetch()}
            />
          )}
        </SectionWrapper>
      </>
    );
  };

  return (
    <Wrapper>
      <ToggleWrapper>
        <TradeToggle
          setToggleState={(type) => {
            setLeague(type);
          }}
          tabNames={category.map((item) => item?.name) || []}
          toggleState={category.map((item) => item?.type) || []}
        />
      </ToggleWrapper>
      {renderContent()}
    </Wrapper>
  );
};

export default AlphaTraderRanking;
