import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  useGetUserInfo,
  useCheckNickname,
  useUpdateProfile,
  useRequestEmailVerification,
  useVerifyEmail,
  QUERY_KEYS,
} from "hooks/queries/useUserQuery";

const Button = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 10px 20px;
  }

  ${(props) =>
    props.primary &&
    `
    background: #4C67FF;
    color: white;
    
    &:hover {
      background: #3651E2;
    }

    &:disabled {
      background: #9DA8BE;
      cursor: not-allowed;
    }
  `}

  ${(props) =>
    props.secondary &&
    `
    background: #F7F8FA;
    color: #121314;
    border: 1px solid #EBEDF5;
    
    &:hover {
      background: #EBEDF5;
    }
  `}
`;

const PageWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;

  @media screen and (max-width: 768px) {
    padding: 24px 16px;
  }
`;

const PageTitle = styled.h1`
  font-family: Pretendard;
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  margin-bottom: 32px;
  color: #121314;
  @media screen and (max-width: 768px) {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 24px;
  }
`;

const ProfileCard = styled.div`
  background: #ffffff;
  border: 1px solid #ebedf5;
  border-radius: 12px;
  padding: 32px;
  @media screen and (max-width: 768px) {
    padding: 24px 16px;
    border-radius: 8px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 28px;
  @media screen and (max-width: 768px) {
    gap: 24px;
  }
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
  color: #121314;
  margin-bottom: 4px;

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`;

const SubLabel = styled.span`
  font-family: Pretendard;
  font-size: 14px;
  color: #9da8be;
  margin-left: 8px;
`;

const ImageSection = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
`;

const ProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #ebedf5;
  @media screen and (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;

const FileInput = styled.input`
  flex: 1;
  padding: 8px;
  border: 1px dashed #ebedf5;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    border-color: #4c67ff;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 12px;

  @media screen and (max-width: 768px) {
    flex-direction: ${(props) => (props.keepRow ? "row" : "column")};
    gap: 8px;

    ${Button} {
      width: ${(props) => (props.keepRow ? "auto" : "100%")};
    }
  }
`;

const Input = styled.input`
  flex: 1;
  padding: 12px 16px;
  border: 1px solid #ebedf5;
  border-radius: 8px;
  font-family: Pretendard;
  font-size: 15px;
  transition: all 0.2s ease;

  @media screen and (max-width: 768px) {
    font-size: 14px;
    padding: 10px 14px;
  }

  &:focus {
    outline: none;
    border-color: #4c67ff;
    box-shadow: 0 0 0 2px rgba(76, 103, 255, 0.1);
  }

  ${(props) =>
    props.error &&
    `
    border-color: #FF3D6C;
    background-color: #FFF5F7;
  `}
`;

const SubmitButton = styled(Button)`
  width: 100%;
  padding: 14px;
  margin-top: 12px;
`;

const VerificationSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 8px;
`;

const StatusMessage = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border-radius: 6px;
  font-size: 14px;
  margin-top: 4px;

  ${(props) => {
    switch (props.type) {
      case "success":
        return `
          background-color: #E8F5E9;
          color: #2E7D32;
        `;
      case "error":
        return `
          background-color: #FFF5F7;
          color: #FF3D6C;
        `;
      case "warning":
        return `
          background-color: #FFF3E0;
          color: #F57C00;
        `;
      case "info":
        return `
          background-color: #E3F2FD;
          color: #1976D2;
        `;
      default:
        return "";
    }
  }}
`;

const UserIdText = styled.div`
  position: absolute;
  bottom: 12px;
  right: 12px;
  font-size: 12px;
  color: #9da8be;
`;

const ProfileCardWrapper = styled.div`
  position: relative; // UserIdText 위치 지정을 위함
`;

const ImageControls = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const ImageActionButtons = styled.div`
  display: flex;
  gap: 8px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    width: 100%;

    ${Button} {
      width: 100%;
    }
  }
`;

const MyInfoPage = () => {
  const navigate = useNavigate();
  const { data: userInfo, isLoading } = useGetUserInfo();
  const { mutate: checkNickname } = useCheckNickname();
  const { mutate: updateProfile } = useUpdateProfile();
  const queryClient = useQueryClient();
  const { mutate: requestVerification } = useRequestEmailVerification();
  const { mutate: verifyEmail } = useVerifyEmail();
  const [previewUrl, setPreviewUrl] = useState(null);

  useEffect(() => {
    if (!isLoading && !userInfo) {
      navigate("/");
    }
  }, [isLoading, userInfo, navigate]);

  const [form, setForm] = useState({
    nickname: "",
    email: "",
    newPicture: null,
    removePicture: false,
  });

  const [verification, setVerification] = useState({
    code: "",
    isRequested: false,
    isVerifying: false,
    isVerified: false,
  });

  const [nicknameStatus, setNicknameStatus] = useState({
    isChecked: false,
    isAvailable: false,
  });

  const [status, setStatus] = useState({
    nickname: { message: "", type: "info" },
    email: { message: "", type: "info" },
    image: { message: "", type: "info" },
  });

  useEffect(() => {
    if (userInfo) {
      setForm({
        nickname: userInfo.nickname || "",
        email: userInfo.email || "",
        newPicture: null,
        removePicture: false,
      });
      // 기존 값으로 초기화할 때는 검증된 것으로 처리
      setNicknameStatus({
        isChecked: true,
        isAvailable: true,
      });
      setVerification({
        code: "",
        isRequested: false,
        isVerifying: false,
        isVerified: true,
      });
      setStatus({
        nickname: { message: "현재 사용중인 닉네임입니다.", type: "success" },
        email: { message: "인증된 이메일입니다.", type: "success" },
        image: { message: "", type: "info" },
      });
    }
  }, [userInfo]);

  useEffect(() => {
    if (form.newPicture) {
      // 기존 URL 해제
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
      // 새 URL 생성 및 저장
      const newUrl = URL.createObjectURL(form.newPicture);
      setPreviewUrl(newUrl);
      return () => {
        URL.revokeObjectURL(newUrl);
      };
    } else {
      // newPicture가 없을 때 기존 URL 해제
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
        setPreviewUrl(null);
      }
    }
  }, [form.newPicture]);

  const handleNicknameCheck = () => {
    if (!form.nickname) {
      setStatus((prev) => ({
        ...prev,
        nickname: { message: "닉네임을 입력해주세요.", type: "error" },
      }));
      return;
    }

    if (form.nickname === userInfo.nickname) {
      setNicknameStatus({ isChecked: true, isAvailable: true });
      setStatus((prev) => ({
        ...prev,
        nickname: { message: "현재 사용중인 닉네임입니다.", type: "success" },
      }));
      return;
    }

    checkNickname(form.nickname, {
      onSuccess: (data) => {
        if (data === true) {
          setNicknameStatus({ isChecked: true, isAvailable: true });
          setStatus((prev) => ({
            ...prev,
            nickname: { message: "사용 가능한 닉네임입니다.", type: "success" },
          }));
        } else {
          setNicknameStatus({ isChecked: true, isAvailable: false });
          setStatus((prev) => ({
            ...prev,
            nickname: { message: "이미 사용중인 닉네임입니다.", type: "error" },
          }));
        }
      },
      onError: () => {
        setStatus((prev) => ({
          ...prev,
          nickname: {
            message: "닉네임 중복 확인에 실패했습니다.",
            type: "error",
          },
        }));
      },
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        setStatus((prev) => ({
          ...prev,
          image: {
            message: "JPG 또는 PNG 파일만 업로드 가능합니다.",
            type: "error",
          },
        }));
        return;
      }
      setForm((prev) => ({ ...prev, newPicture: file, removePicture: false }));
      setStatus((prev) => ({
        ...prev,
        image: { message: "이미지가 선택되었습니다.", type: "success" },
      }));
    }
  };

  const handleImageDelete = () => {
    setForm((prev) => ({
      ...prev,
      newPicture: null,
      removePicture: true,
    }));
    setStatus((prev) => ({
      ...prev,
      image: {
        message: "이미지가 삭제 대기중입니다.",
        type: "info",
      },
    }));
  };

  const handleRequestVerification = () => {
    if (!form.email) {
      setStatus((prev) => ({
        ...prev,
        email: { message: "이메일을 입력해주세요.", type: "error" },
      }));
      return;
    }

    if (form.email === userInfo.email) {
      setVerification((prev) => ({ ...prev, isVerified: true }));
      setStatus((prev) => ({
        ...prev,
        email: { message: "이미 인증된 이메일입니다.", type: "success" },
      }));
      return;
    }

    requestVerification(form.email, {
      onSuccess: () => {
        setVerification((prev) => ({ ...prev, isRequested: true }));
        setStatus((prev) => ({
          ...prev,
          email: {
            message: "인증 코드가 이메일로 전송되었습니다.",
            type: "info",
          },
        }));
      },
      onError: () => {
        setStatus((prev) => ({
          ...prev,
          email: { message: "인증 코드 전송에 실패했습니다.", type: "error" },
        }));
      },
    });
  };

  const handleVerifyEmail = () => {
    if (!verification.code) {
      setStatus((prev) => ({
        ...prev,
        email: { message: "인증 코드를 입력해주세요.", type: "error" },
      }));
      return;
    }

    verifyEmail(
      { email: form.email, code: verification.code },
      {
        onSuccess: () => {
          setVerification({
            code: "",
            isRequested: false,
            isVerifying: false,
            isVerified: true,
          });
          setStatus((prev) => ({
            ...prev,
            email: { message: "이메일이 인증되었습니다.", type: "success" },
          }));
        },
        onError: () => {
          setStatus((prev) => ({
            ...prev,
            email: { message: "잘못된 인증 코드입니다.", type: "error" },
          }));
        },
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const updateData = {};

    // 닉네임이 변경되었고, 검증이 안된 경우에만 체크
    if (
      form.nickname !== userInfo.nickname &&
      (!nicknameStatus.isChecked || !nicknameStatus.isAvailable)
    ) {
      setStatus((prev) => ({
        ...prev,
        nickname: { message: "닉네임 중복 확인이 필요합니다.", type: "error" },
      }));
      return;
    }

    // 이메일이 변경되었고, 검증이 안된 경우에만 체크
    if (form.email !== userInfo.email && !verification.isVerified) {
      setStatus((prev) => ({
        ...prev,
        email: { message: "이메일 인증이 필요합니다.", type: "error" },
      }));
      return;
    }

    if (form.nickname !== userInfo.nickname)
      updateData.nickname = form.nickname;
    if (form.email !== userInfo.email) updateData.email = form.email;
    if (form.removePicture) updateData.remove_picture = true;
    else if (form.newPicture) updateData.picture = form.newPicture;

    if (Object.keys(updateData).length === 0) {
      setStatus({
        nickname: { message: "변경된 내용이 없습니다.", type: "info" },
        email: { message: "변경된 내용이 없습니다.", type: "info" },
      });
      return;
    }

    if (form.newPicture || form.removePicture) {
      queryClient.setQueryData([QUERY_KEYS.USER], (old) => ({
        ...old,
        picture: form.newPicture ? URL.createObjectURL(form.newPicture) : null,
      }));
    }

    updateProfile(
      {
        user: {
          nickname:
            form.nickname !== userInfo.nickname ? form.nickname : undefined,
          email: form.email !== userInfo.email ? form.email : undefined,
          removePicture: form.removePicture,
          picture: form.newPicture,
        },
      },
      {
        onSuccess: () => {
          if (previewUrl) {
            URL.revokeObjectURL(previewUrl);
            setPreviewUrl(null);
          }
          setForm((prev) => ({
            ...prev,
            newPicture: null,
            removePicture: false,
          }));
          setStatus({
            nickname: {
              message: "프로필이 업데이트되었습니다.",
              type: "success",
            },
            email: { message: "프로필이 업데이트되었습니다.", type: "success" },
          });
          window.dispatchEvent(new Event("profile-image-update"));
        },
        onError: () => {
          queryClient.invalidateQueries([QUERY_KEYS.USER]);
          queryClient.invalidateQueries([QUERY_KEYS.USER_PROFILE]);
          setStatus({
            nickname: {
              message: "프로필 업데이트에 실패했습니다.",
              type: "error",
            },
            email: {
              message: "프로필 업데이트에 실패했습니다.",
              type: "error",
            },
          });
        },
      }
    );
  };
  const getProfileImageSrc = () => {
    if (form.newPicture && previewUrl) {
      return previewUrl;
    }
    if (form.removePicture || !userInfo?.picture) {
      return "https://api.alphanote.io/static/images/anonymous.png";
    }
    // 캐시 방지를 위한 타임스탬프 추가
    return `https://api.alphanote.io/${
      userInfo.picture
    }?t=${new Date().getTime()}`;
  };

  return (
    <PageWrapper>
      {isLoading ? (
        <div className="flex justify-center items-center min-h-[400px]">
          로딩중...
        </div>
      ) : !userInfo ? (
        <div className="flex justify-center items-center min-h-[400px]">
          리다이렉트 중...
        </div>
      ) : (
        <>
          <PageTitle>프로필 관리</PageTitle>
          <ProfileCardWrapper>
            <ProfileCard>
              <Form onSubmit={handleSubmit}>
                <FormSection>
                  <Label>프로필 이미지</Label>
                  <ImageSection>
                    <ProfileImage src={getProfileImageSrc()} alt="프로필" />
                    <ImageControls>
                      <ImageActionButtons>
                        <FileInput
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          onChange={handleFileChange}
                        />
                        <Button
                          type="button"
                          $secondary
                          onClick={handleImageDelete}
                          disabled={!userInfo?.picture && !form.newPicture}
                        >
                          이미지 삭제
                        </Button>
                      </ImageActionButtons>
                      {status.image?.message && (
                        <StatusMessage type={status.image.type}>
                          {status.image.message}
                        </StatusMessage>
                      )}
                    </ImageControls>
                  </ImageSection>
                </FormSection>

                <FormSection>
                  <Label>닉네임</Label>
                  <InputWrapper>
                    <Input
                      type="text"
                      value={form.nickname}
                      onChange={(e) => {
                        const newNickname = e.target.value;
                        setForm((prev) => ({
                          ...prev,
                          nickname: newNickname,
                        }));
                        if (newNickname !== userInfo.nickname) {
                          setNicknameStatus({
                            isChecked: false,
                            isAvailable: false,
                          });
                          setStatus((prev) => ({
                            ...prev,
                            nickname: {
                              message: "닉네임 중복 확인이 필요합니다.",
                              type: "warning",
                            },
                          }));
                        } else {
                          setNicknameStatus({
                            isChecked: true,
                            isAvailable: true,
                          });
                          setStatus((prev) => ({
                            ...prev,
                            nickname: {
                              message: "현재 사용중인 닉네임입니다.",
                              type: "success",
                            },
                          }));
                        }
                      }}
                      error={
                        nicknameStatus.isChecked && !nicknameStatus.isAvailable
                      }
                    />
                    <Button
                      type="button"
                      secondary
                      onClick={handleNicknameCheck}
                      disabled={form.nickname === userInfo?.nickname}
                    >
                      중복 확인
                    </Button>
                  </InputWrapper>
                  {status.nickname.message && (
                    <StatusMessage type={status.nickname.type}>
                      {status.nickname.message}
                    </StatusMessage>
                  )}
                </FormSection>

                <FormSection>
                  <Label>
                    이메일
                    {/* <SubLabel>({userInfo?.social.provider} 계정)</SubLabel> */}
                  </Label>
                  <InputWrapper>
                    <Input
                      type="email"
                      value={form.email}
                      onChange={(e) => {
                        const newEmail = e.target.value;
                        setForm((prev) => ({ ...prev, email: newEmail }));
                        if (newEmail !== userInfo.email) {
                          setVerification({
                            code: "",
                            isRequested: false,
                            isVerifying: false,
                            isVerified: false,
                          });
                          setStatus((prev) => ({
                            ...prev,
                            email: {
                              message: "이메일 인증이 필요합니다.",
                              type: "warning",
                            },
                          }));
                        } else {
                          setVerification({
                            code: "",
                            isRequested: false,
                            isVerifying: false,
                            isVerified: true,
                          });
                          setStatus((prev) => ({
                            ...prev,
                            email: {
                              message: "현재 사용중인 이메일입니다.",
                              type: "success",
                            },
                          }));
                        }
                      }}
                    />
                    <Button
                      type="button"
                      primary
                      onClick={handleRequestVerification}
                      disabled={
                        verification.isRequested ||
                        form.email === userInfo.email
                      }
                    >
                      인증코드 받기
                    </Button>
                  </InputWrapper>
                  {status.email.message && (
                    <StatusMessage type={status.email.type}>
                      {status.email.message}
                    </StatusMessage>
                  )}

                  {verification.isRequested && (
                    <VerificationSection>
                      <InputWrapper keepRow={true}>
                        <Input
                          type="text"
                          placeholder="인증 코드 입력"
                          value={verification.code}
                          onChange={(e) =>
                            setVerification((prev) => ({
                              ...prev,
                              code: e.target.value,
                            }))
                          }
                        />
                        <Button
                          type="button"
                          primary
                          onClick={handleVerifyEmail}
                          disabled={!verification.code}
                        >
                          확인
                        </Button>
                      </InputWrapper>
                    </VerificationSection>
                  )}
                </FormSection>

                <SubmitButton
                  type="submit"
                  primary
                  disabled={
                    (form.nickname !== userInfo.nickname &&
                      (!nicknameStatus.isChecked ||
                        !nicknameStatus.isAvailable)) ||
                    (form.email !== userInfo.email && !verification.isVerified)
                  }
                >
                  프로필 업데이트
                </SubmitButton>
              </Form>
            </ProfileCard>
            <UserIdText>UUID: {userInfo.id}</UserIdText>
          </ProfileCardWrapper>
        </>
      )}
    </PageWrapper>
  );
};

export default MyInfoPage;
