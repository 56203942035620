import { CircularProgress } from "@mui/material";
import { ReactComponent as CloseIcon } from "assets/images/ico_close_m.svg";
import { useDeleteMyNoteHistory } from "hooks/queries/useAlphaTraderDetailQuery";
import useHttpClient from "hooks/useHttpClient";
import { DeleteIcon, LinkOutIcon } from "pages/TraderDetail/AlphaDetailMyNote";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import ReactModal from "react-modal";
import { useMutation } from "react-query";
import styled from "styled-components";
import { addCommaToInt, formatTimestampToYMD } from "utils/utils";
import { useUpdateMyReport } from "hooks/queries/useAlphaTraderDetailQuery";

// Constants
const API_URL = "https://api.alphanote.io/api/";
const FILE_STATUS = {
  DONE: "done",
  UPLOADING: "uploading",
  ERROR: "error",
};

const MODAL_STYLES = {
  overlay: {
    zIndex: 1000,
    backgroundColor: "#00000070",
  },
  content: {
    width: "520px",
    inset: "unset",
    margin: "50vh auto",
    padding: 0,
    transform: "translateY(-50%)",
    position: "relative",
    borderRadius: "8px",
  },
};

// Styled Components
const ModalWrapper = styled.div`
  border-radius: 8px;
  font-family: Pretendard;
  text-align: left;
`;

const ModalHeader = styled.div`
  padding: 20px 20px 12px;
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  font-weight: 600;
  line-height: 20.29px;

  svg {
    cursor: pointer;
    opacity: ${(props) => (props.isuploading ? 0.5 : 1)};
  }
`;

const ModalBody = styled.div`
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.9px;
  text-align: left;

  .infourl {
    margin-bottom: 1rem;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    text-align: right;
    color: #4c67ff;
    display: flex;
    align-items: center;
    justify-content: right;
    gap: 4px;
    text-decoration: underline;
    cursor: pointer;
  }

  ul {
    color: #7d7e80;
    font-size: 12px;
    font-weight: 400;
    line-height: 19px;
    padding: 0 20px;
    margin-bottom: 0;
  }
`;

const ModalBottom = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 16px 20px 20px 20px;
`;

const ButtonWrapper = styled.div`
  box-sizing: border-box;
  width: 100px;
  height: 40px;
  border-radius: 6px;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 600;
  line-height: 16.71px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => (props.secondary ? "#ffffff" : "#000000")};
  color: ${(props) => (props.secondary ? "#000000" : "#ffffff")};
  border: ${(props) => (props.secondary ? "1px solid #E7E9EF" : "none")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const FileListWrapper = styled.div`
  width: 100%;
  max-height: 210px;
  border-collapse: collapse;
  margin-top: 20px;
  font-family: Pretendard;
  overflow-y: auto;

  th,
  td {
    box-sizing: border-box;
    padding: 0 8px;
    text-align: right;
    height: 30px;
    font-size: 12px;
    font-weight: 500;
    line-height: 14.32px;

    &:nth-child(1) {
      width: 190px;
      text-align: left;
      display: flex;
      align-items: center;
      gap: 12px;
    }
    &:nth-child(2) {
      width: 93px;
      text-align: left;
    }
    &:nth-child(3) {
      width: 93px;
      text-align: left;
    }
    &:nth-child(4) {
      width: 64px;
      text-align: right;
    }
    &:nth-child(5) {
      width: 40px;
    }
  }

  th {
    background: #f2f4f7;
    color: #7d7e80;
    &:nth-child(1) {
      border-radius: 8px 0 0 8px;
    }
    &:nth-child(5) {
      border-radius: 0 8px 8px 0;
    }
  }

  td {
    height: 40px;
    font-weight: 400;
    color: #323233;
    border-bottom: 1px solid #f2f4f7;

    .filename {
      width: 170px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    svg {
      cursor: pointer;
    }
  }
`;

const FileSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Pretendard;

  .info {
    margin-top: 8px;
    font-size: 15px;
    font-weight: 500;
    line-height: 17.9px;
    text-align: center;
  }
`;

const FileSelectButton = styled.div`
  width: 72px;
  height: 32px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e7e9ef;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  color: #4b4c4d;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 21px;
`;

// Icons Components
const CloudIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5073 12.4476C16.3738 12.3249 16.1957 12.25 16 12.25C15.8447 12.25 15.7004 12.2972 15.5807 12.3781C15.5507 12.3984 15.5222 12.4208 15.4955 12.445L9.9955 17.445C9.68901 17.7237 9.66642 18.198 9.94505 18.5045C10.2237 18.811 10.698 18.8336 11.0045 18.555L15.25 14.6954L15.25 30C15.25 30.4142 15.5858 30.75 16 30.75C16.4142 30.75 16.75 30.4142 16.75 30L16.75 14.6954L20.9955 18.555C21.302 18.8336 21.7763 18.811 22.055 18.5045C22.3336 18.198 22.311 17.7237 22.0045 17.445L16.5073 12.4476Z"
      fill="#DCDEE3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.37245 7.53742L9.64572 7.9902L10.2284 6.77089C11.437 4.24194 14.0165 2.5 17 2.5C20.9292 2.5 24.154 5.52258 24.4739 9.36836L24.5626 10.4355L25.6007 10.6982C28.4174 11.411 30.5 13.9641 30.5 17C30.5 20.5899 27.5899 23.5 24 23.5H20H19V25H20H24C28.4183 25 32 21.4183 32 17C32 13.2611 29.435 10.1212 25.9687 9.24405C25.5848 4.62724 21.716 1 17 1C13.4172 1 10.3233 3.09358 8.87503 6.12412C7.28954 5.56031 5.45109 5.91292 4.18203 7.18198C2.84966 8.51435 2.52745 10.4744 3.2154 12.1103C1.28104 13.3558 0 15.5283 0 18C0 21.866 3.13401 25 7 25H13V23.5H7C3.96243 23.5 1.5 21.0376 1.5 18C1.5 16.0595 2.50377 14.3526 4.02747 13.3715L5.08628 12.6897L4.59811 11.5288C4.13831 10.4354 4.35612 9.12922 5.24269 8.24264C6.08713 7.3982 7.31182 7.16025 8.37245 7.53742Z"
      fill="#DCDEE3"
    />
  </svg>
);

const ErrorIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="9" fill="#FF5857" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8101 5.85355C13.0054 5.65829 13.0054 5.34171 12.8101 5.14645C12.6148 4.95118 12.2983 4.95118 12.103 5.14645L8.97827 8.27116L5.85355 5.14645C5.65829 4.95118 5.34171 4.95118 5.14645 5.14645C4.95118 5.34171 4.95118 5.65829 5.14645 5.85355L8.27116 8.97827L5.14647 12.103C4.95121 12.2982 4.95121 12.6148 5.14647 12.8101C5.34173 13.0053 5.65831 13.0053 5.85357 12.8101L8.97827 9.68538L12.103 12.8101C12.2982 13.0053 12.6148 13.0053 12.8101 12.8101C13.0053 12.6148 13.0053 12.2982 12.8101 12.103L9.68538 8.97827L12.8101 5.85355Z"
      fill="white"
    />
  </svg>
);

const DoneIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9" cy="9" r="9" fill="#00B488" />
    <path
      d="M4.31689 9.18311L7.49888 12.3651L13.682 6.18198"
      stroke="white"
      strokeWidth="1.5"
    />
  </svg>
);

// Main Component
const MyNoteUploadModal = ({
  isOpen,
  onCancel,
  title = "",
  setSnackOpen = () => {},
  body = <></>,
  onClickConfirm = () => {},
  refetchList = [],
}) => {
  const [uploadList, setUploadList] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const httpClient = useHttpClient(API_URL);
  const { mutate: deleteMutate, data: deleteData } = useDeleteMyNoteHistory();
  const { mutate: mutateUpdateReport } = useUpdateMyReport();

  const [showReportConfirm, setShowReportConfirm] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);

  // File upload mutation
  const handleFileUpload = useMutation(
    async (file) => {
      const formData = new FormData();
      formData.append("file", file);
      const response = await httpClient.post(
        "/v1b/my/history_attachment",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return { data: response.data, file };
    },
    {
      onSuccess: ({ data, file }) => {
        const { temp_id, n_stock, n_trx, first_transaction, last_transaction } =
          data;
        updateFileStatus(file.name, {
          temp_id,
          status: FILE_STATUS.DONE,
          n_stock,
          n_trx,
          first_transaction,
          last_transaction,
        });
      },
      onError: (error, file) => {
        updateFileStatus(file.name, {
          status: FILE_STATUS.ERROR,
          first_transaction: "파일 양식을 읽어오는데 실패하였습니다.",
        });
      },
    }
  );

  // Final upload mutation
  const uploadMutation = useMutation(() => httpClient.post("/v1b/my/history"), {
    onSuccess: () => {
      setIsUploading(false);
      refetchList.forEach((refetch) => refetch());
      setShowReportConfirm(true);
      onCancel();
      setUploadList([]);
      setIsReady(false);
    },
    onError: (error) => {
      setIsUploading(false);
      console.error("Error uploadMutation:", error);
    },
  });

  const handleReportConfirm = () => {
    // await till report is generated
    setReportLoading(true);
    mutateUpdateReport(
      {
        start: "00000000",
        end: "99999999",
      },
      {
        onSuccess: () => {
          setShowReportConfirm(false);
        },
        onError: (error) => {
          console.error("Error updating report:", error);
        },
        onSettled: () => {
          setReportLoading(false);
        },
      }
    );
  };

  const handleReportCancel = () => {
    setShowReportConfirm(false);
  };

  // File handling functions
  const updateFileStatus = (filename, updates) => {
    setUploadList((prevList) =>
      prevList.map((f) => (f.filename === filename ? { ...f, ...updates } : f))
    );
  };

  const onDrop = useCallback((acceptedFiles) => {
    const newFiles = acceptedFiles.map((file) => ({
      filename: file.name,
      first_transaction: 0,
      last_transaction: 0,
      n_stock: 0,
      n_trx: 0,
      status: FILE_STATUS.UPLOADING,
      temp_id: null,
    }));

    setUploadList((prevList) => {
      const existingFilenames = prevList.map((file) => file.filename);
      const filteredNewFiles = newFiles.filter(
        (file) => !existingFilenames.includes(file.filename)
      );
      return [...prevList, ...filteredNewFiles];
    });

    acceptedFiles.forEach((file) => handleFileUpload.mutate(file));
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  // Event handlers
  const handleClickCancel = () => {
    if (!isUploading) {
      onCancel();
      setUploadList([]);
      setIsReady(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isReady || isUploading) return;

    setIsUploading(true);
    uploadMutation.mutate();
  };

  // Effects
  useEffect(() => {
    if (uploadList.length > 0) {
      const hasDone = uploadList.some(
        (file) => file.status === FILE_STATUS.DONE
      );
      const hasLoading = uploadList.some(
        (file) => file.status === FILE_STATUS.UPLOADING
      );
      setIsReady(hasDone && !hasLoading);
    } else {
      setIsReady(false);
    }
  }, [uploadList]);

  useEffect(() => {
    setUploadList([]);
    setIsReady(false);
  }, []);

  useEffect(() => {
    if (deleteData?.removed_id) {
      setUploadList((prevList) =>
        prevList.filter((file) => file.temp_id !== deleteData.removed_id[0])
      );
    }
  }, [deleteData]);

  // Render components
  const renderFileStatus = (file) => {
    switch (file.status) {
      case FILE_STATUS.ERROR:
        return <ErrorIcon />;
      case FILE_STATUS.DONE:
        return <DoneIcon />;
      case FILE_STATUS.UPLOADING:
        return <CircularProgress size={24} color="inherit" />;
      default:
        return null;
    }
  };

  const renderFileDetails = (file) => {
    if (file.status === FILE_STATUS.DONE) {
      return (
        <>
          <td>{formatTimestampToYMD(file.first_transaction)}</td>
          <td>{formatTimestampToYMD(file.last_transaction)}</td>
          <td>{addCommaToInt(file.n_trx)}건</td>
          <td onClick={() => deleteMutate({ temp_id: file.temp_id })}>
            <DeleteIcon fill="#4B4C4D" />
          </td>
        </>
      );
    }
    return (
      <>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </>
    );
  };

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        style={MODAL_STYLES}
        onRequestClose={handleClickCancel}
        shouldCloseOnOverlayClick={false}
      >
        <ModalWrapper>
          <ModalHeader isuploading={isUploading}>
            <div>{title}</div>
            <div onClick={isUploading ? undefined : handleClickCancel}>
              <CloseIcon />
            </div>
          </ModalHeader>

          <ModalBody>
            <div
              className="infourl"
              onClick={() =>
                window.open(
                  "https://www.notion.so/arpa/a15cd364395f4ef18b1f0d8a7e2f3a89",
                  "_blank"
                )
              }
            >
              매매일지 다운로드 방법 보러가기
              <LinkOutIcon fill="#4C67FF" />
            </div>

            <div
              {...getRootProps()}
              style={{
                height: "200px",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                border: "2px dashed #C8C9CC",
                borderRadius: "8px",
                padding: "20px",
                cursor: "pointer",
                backgroundColor: "#F9FAFC",
              }}
            >
              <input {...getInputProps()} />
              <FileSection>
                <CloudIcon />
                <div className="info">파일을 이곳에 드래그 해주세요.</div>
                <FileSelectButton>파일 선택</FileSelectButton>
              </FileSection>
            </div>

            {uploadList?.length > 0 && (
              <FileListWrapper>
                <thead>
                  <tr>
                    <th>파일명</th>
                    <th>최초 거래일</th>
                    <th>마지막 거래일</th>
                    <th>체결 건수</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {uploadList.map((file, index) => (
                    <tr key={index}>
                      <td>
                        <div className="filename">{file.filename}</div>
                        {renderFileStatus(file)}
                      </td>
                      {renderFileDetails(file)}
                    </tr>
                  ))}
                </tbody>
              </FileListWrapper>
            )}

            <ul>
              <li>
                영웅문 '0343' 화면에서 csv 파일로 저장 후 업로드 해주세요.
              </li>
              <li>중복된 데이터는 자동으로 한 번만 저장됩니다.</li>
              <li>정해진 형식이 아니면 파일이 업로드 되지 않습니다.</li>
              <li>추후 더 편하게 업로드 할 수 있도록 준비하겠습니다.</li>
            </ul>
          </ModalBody>

          <ModalBottom>
            <ButtonWrapper
              secondary
              onClick={handleClickCancel}
              disabled={isUploading}
            >
              취소
            </ButtonWrapper>
            <ButtonWrapper
              onClick={handleSubmit}
              disabled={!isReady || isUploading}
            >
              {isUploading ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <CircularProgress size={16} color="inherit" />
                  <span>업로드 중</span>
                </div>
              ) : (
                "확인"
              )}
            </ButtonWrapper>
          </ModalBottom>
        </ModalWrapper>
      </ReactModal>
      <ReactModal
        isOpen={showReportConfirm}
        style={MODAL_STYLES}
        shouldCloseOnOverlayClick={false}
      >
        <ModalWrapper>
          <ModalHeader>
            <div>레포트 생성</div>
            {!reportLoading && (
              <div onClick={handleReportCancel}>
                <CloseIcon />
              </div>
            )}
          </ModalHeader>
          <ModalBody>
            {reportLoading ? (
              <div
                style={{
                  padding: "40px 0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <CircularProgress size={32} />
                <div>레포트 생성 중입니다...</div>
              </div>
            ) : (
              <div style={{ padding: "20px 0" }}>
                현재까지 업로드한 매매일지를 바탕으로 레포트를 생성하시겠습니까?
              </div>
            )}
          </ModalBody>
          <ModalBottom>
            <ButtonWrapper
              secondary
              onClick={handleReportCancel}
              disabled={reportLoading}
            >
              취소
            </ButtonWrapper>
            <ButtonWrapper
              onClick={handleReportConfirm}
              disabled={reportLoading}
            >
              {reportLoading ? (
                <div
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <CircularProgress size={16} color="inherit" />
                  <span>생성 중</span>
                </div>
              ) : (
                "확인"
              )}
            </ButtonWrapper>
          </ModalBottom>
        </ModalWrapper>
      </ReactModal>
    </>
  );
};

export default MyNoteUploadModal;
