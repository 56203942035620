import React from "react";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
`;

const Title = styled.h1`
  font-family: Pretendard, sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
`;

const Description = styled.p`
  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  margin-bottom: 30px;
  text-align: center;
  color: #666;
`;

const Button = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  background-color: #ff3e61;
  font-family: Pretendard, sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #ff2545;
  }
`;

const LoginRequired = () => {
  const handleLoginClick = () => {
    const loginWindow = window.open("/login", "Login", "width=500,height=600");

    const checkWindowClosed = setInterval(() => {
      if (loginWindow.closed) {
        clearInterval(checkWindowClosed);
        const token = localStorage.getItem("access_token");
        if (token) {
          // to nothing
        }
      }
    }, 500);
  };

  return (
    <Container>
      <Title>로그인이 필요합니다</Title>
      <Description>
        안녕하세요! 알파노트 팀입니다.
        <br />
        로그인하시면 모든 서비스를 자유롭게 이용하실 수 있습니다.
        <br />
        지금 바로 로그인하고 트레이딩의 새로운 세계를 경험해보세요!
      </Description>
      <Button onClick={handleLoginClick}>로그인하기</Button>
    </Container>
  );
};

export default LoginRequired;
