// TopNavigation.js
import { ReactComponent as SearchIcon } from "assets/images/ico_search.svg";
import { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import useAuth from "utils/auth";
import logoPng from "assets/images/logo_alphanote.png";
import AlphaRangeSelect from "components/AlphaRangeSelect";

const Header = styled.header`
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1000;
`;

const NavWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-bottom: ${(props) =>
    props.$isscrolled === "true"
      ? props.$is_white === "white"
        ? "1px solid #F2F4F7"
        : "1px solid rgba(0, 0, 0, 0.1)"
      : "none"};
  background: ${(props) =>
    props.$is_white === "white" ? "#ffffff" : "#16191F"};
  color: ${(props) => (props.$is_white === "white" ? "#4B4C4D" : "#fff")};
  min-height: 60px;
  padding: 8px 0;
`;

const Nav = styled.nav`
  max-width: 1680px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  gap: 8px;
  position: relative;

  .fit {
    display: flex;
    align-items: center;
  }

  .logo {
    flex: 1; // left section이 남은 공간을 차지하도록
    min-width: 0;
    order: 1;
  }

  .center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .login {
    order: 2;
    white-space: nowrap; // 로그인 섹션이 줄바꿈되지 않도록
  }

  @media (max-width: 1024px) {
    flex-wrap: wrap;
    justify-content: space-between;

    .fit {
      flex: 0 0 auto; // 더 이상 100% 너비를 차지하지 않음
      justify-content: flex-start; // 왼쪽 정렬
    }

    .logo {
      order: 1;
    }

    .login {
      order: 2;
    }

    .center {
      order: 3;
      position: static;
      transform: none;
      width: 100%;
      justify-content: center;
      margin: 8px 0;
    }
  }

  @media (max-width: 768px) {
    .logo {
      flex: 1;
    }
  }
`;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 8px;
`;

const LogoImage = styled.img`
  margin-left: 8px;
  height: 24px;
  width: auto;
`;

const LogoText = styled.span`
  font-family: Pretendard;
  font-size: 19px;
  font-weight: 800;
  line-height: 22.67px;
  text-align: center;
  color: ${(props) => (props.$is_white === "white" ? "#000000" : "#fff")};

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavigationList = styled.ul`
  list-style: none;
  display: flex;
  padding-inline-start: 16px;
  margin: 0;
  gap: 4px;

  @media (max-width: 768px) {
    padding-inline-start: 8px;
  }
`;

const NavigationItem = styled.li`
  align-self: center;
`;

const NavigationLoginItem = styled.div`
  margin-left: 6px;
  color: #fff;
`;

const NavigationLink = styled(Link)`
  padding: 8px 12px; // 패딩 축소
  text-decoration: none;
  color: ${(props) =>
    props.$is_white === "white"
      ? props.is_active === "active"
        ? "#000000"
        : "#4B4C4D"
      : props.is_active === "active"
      ? "#FFFFFF"
      : "#969799"};
  font-family: Pretendard;
  font-size: 15px;
  font-weight: ${(props) => (props.is_active === "active" ? "700" : "400")};
  line-height: 17.9px;
  text-align: center;
  white-space: nowrap; // 텍스트 줄바꿈 방지

  &:hover {
    color: ${(props) => (props.$is_white === "white" ? "#000000" : "#fff")};
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 6px 8px; // 모바일에서 더 작은 패딩
  }
`;

const NavigationRightSection = styled.div`
  display: flex;
  align-items: center;
  margin-right: 40px;

  @media (max-width: 1024px) {
    margin-right: 0;
  }

  svg {
    width: 20px;
    height: 20px;
    padding: 10px;
    cursor: pointer;
  }
`;

const ProfileSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const ProfileImage = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
  overflow: hidden;
  background: #ffffff;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ProfileDropdown = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 0.5rem;
  background: white;
  border-radius: 0.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #f1f1f1;
  overflow: hidden;
  min-width: 120px;
  display: ${(props) => (props.$isOpen ? "block" : "none")};
`;

const DropdownItem = styled(Link)`
  display: block;
  padding: 0.75rem 1rem;
  color: #4b4c4d;
  font-size: 0.875rem;
  text-decoration: none;
  transition: background 0.2s;
  border: none;
  background: none;
  width: 100%;
  text-align: left;
  cursor: pointer;

  &:hover {
    background: #f5f5f7;
  }
`;

const NavigationRightContent = ({
  isWhiteGNB,
  isLoggedIn,
  currentUser,
  onLogout,
  isActive,
  onLogin,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleProfileClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleLogoutClick = (e) => {
    e.preventDefault();
    setIsDropdownOpen(false);
    onLogout();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const getProfileImageUrl = () => {
    if (!currentUser?.id)
      return "https://api.alphanote.io/static/images/anonymous.png";
    return `https://api.alphanote.io/static/uploads/profile/${
      currentUser.id
    }.png?v=${Date.now()}`;
  };

  return (
    <NavigationRightSection>
      <NavigationLoginItem>
        <NavigationLink
          $is_white={isWhiteGNB}
          target="_blank"
          to="https://www.arpa.co.kr/11face638ed680baa626e58691b2e3a8"
        >
          공지사항
        </NavigationLink>
      </NavigationLoginItem>
      <NavigationLoginItem>
        {isLoggedIn ? (
          <ProfileSection ref={dropdownRef}>
            <ProfileImage onClick={handleProfileClick}>
              <img
                src={getProfileImageUrl()}
                alt="Profile"
                onError={(e) => {
                  e.target.src =
                    "https://api.alphanote.io/static/images/anonymous.png";
                }}
              />
            </ProfileImage>
            <ProfileDropdown $isOpen={isDropdownOpen}>
              <DropdownItem to="/info" onClick={() => setIsDropdownOpen(false)}>
                내 정보
              </DropdownItem>
              <DropdownItem as="button" onClick={handleLogoutClick}>
                로그아웃
              </DropdownItem>
            </ProfileDropdown>
          </ProfileSection>
        ) : (
          <NavigationLink
            $is_white={isWhiteGNB}
            is_active={isActive("/login")}
            to="/login"
            onClick={onLogin}
          >
            로그인
          </NavigationLink>
        )}
      </NavigationLoginItem>
    </NavigationRightSection>
  );
};

const TopNavigation = () => {
  const { isLoggedIn, currentUser, checkTokenValidity, logout } = useAuth();
  const location = useLocation();
  const [isScrolled, setIsScrolled] = useState(false);

  const hideTopNavigation = location.pathname.startsWith("/notee");
  const activeUrl = location.pathname;
  const WHITE_GNB = [
    "/hotstock",
    "/trader",
    "/competition",
    "/note",
    "/info",
    "/",
    "/freeboard",
  ];
  const isWhiteGNB = WHITE_GNB.includes(activeUrl) ? "white" : "black";
  const isCompetition =
    activeUrl.includes("/competition") ||
    activeUrl.endsWith("/trader") ||
    activeUrl.endsWith("/hotstock");
  const isNoCompetition = !isCompetition;

  const handleLoginClick = (e) => {
    e.preventDefault();
    const loginWindow = window.open("/login", "Login", "width=500,height=600");
    const checkWindowClosed = setInterval(() => {
      if (loginWindow.closed) {
        clearInterval(checkWindowClosed);
        checkTokenValidity();
      }
    }, 500);
  };

  const handleLogoutClick = () => {
    logout();
    window.location.href = "/logout";
    window.location.reload();
  };

  const isActive = (url) => (activeUrl.includes(url) ? "active" : "deactive");

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    checkTokenValidity();

    const tokenCheckInterval = setInterval(() => {
      checkTokenValidity();
    }, 10000);

    const handleStorageChange = () => {
      checkTokenValidity();
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      clearInterval(tokenCheckInterval);
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  if (hideTopNavigation) {
    return null;
  }

  return (
    <Header>
      <NavWrapper
        $is_white={isWhiteGNB}
        $isscrolled={isScrolled ? "true" : "false"}
      >
        <Nav>
          <div className="fit logo">
            <LogoContainer to="/">
              <LogoImage src={logoPng} alt="AlphaNote Logo" />
              <LogoText $is_white={isWhiteGNB}>AlphaNote</LogoText>
            </LogoContainer>
            <NavigationList>
              <NavigationItem>
                <NavigationLink
                  $is_white={isWhiteGNB}
                  is_active={isActive("/mynote")}
                  to="/mynote"
                >
                  MY노트
                </NavigationLink>
              </NavigationItem>
              <NavigationItem>
                <NavigationLink
                  $is_white={isWhiteGNB}
                  is_active={isActive("/trader")}
                  to="/trader"
                >
                  트레이더
                </NavigationLink>
              </NavigationItem>
              <NavigationItem>
                <NavigationLink
                  $is_white={isWhiteGNB}
                  is_active={isActive("/freeboard")}
                  to="/freeboard"
                >
                  게시판
                </NavigationLink>
              </NavigationItem>
            </NavigationList>
          </div>
          {!isNoCompetition && (
            <div className="center">
              <AlphaRangeSelect isDarkMode={isWhiteGNB === "black"} />
            </div>
          )}
          <div className="fit login">
            <NavigationRightContent
              isWhiteGNB={isWhiteGNB}
              isLoggedIn={isLoggedIn}
              currentUser={currentUser}
              onLogout={handleLogoutClick}
              isActive={isActive}
              onLogin={handleLoginClick}
            />
          </div>
        </Nav>
      </NavWrapper>
    </Header>
  );
};

export default TopNavigation;
