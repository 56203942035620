import { useState } from "react";
import styled from "styled-components";

import { ReactComponent as ShareIcon } from "assets/images/ico_share_gray.svg";

import ShareModal from "components/Modals/ShareModal";

import { Snackbar } from "@mui/material";
import AlphaTag from "components/AlphaTag";
import { TRADE_STYLE_TYPE } from "utils/consts";
import { formatDateToMD } from "utils/utils";

const UserInfoWrapper = styled.div`
  height: 127px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: end;

  .wrapper {
    max-width: 1128px;
    width: 100%;
    padding-bottom: 20px;
    display: flex;
    align-items: end;
    justify-content: space-between;
  }

  .user {
    font-family: Pretendard;
    font-size: 20px;
    font-weight: 600;
    line-height: 23.87px;
    text-align: left;
    color: #000000;
    cursor: pointer;
    transition: color 0.3s ease;
  }

  .user:hover {
    font-weight: bold;
  }
`;

const ActionSection = styled.div`
  display: flex;

  .round-button {
    border: 1px solid #e1e5ed;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
`;

export const AlphaNoteUserInfo = ({
  userName = "",
  trader = {},
  competition = {},
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { nickname, nick, league, rank, style, cid, cuid } = trader;
  const { broker, name, on_going, start, end } = competition;
  const [snackOpen, setSnackOpen] = useState(false);
  const handleSnackClick = () => {
    setSnackOpen(true);
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  // const { competitions, current_competition } = competitionData;
  // const leagueData = competitions.find((item) => item.cid === data.cid);
  const leagueData = {
    broker: broker,
    name: cid,
  };

  return (
    <UserInfoWrapper>
      <div className="wrapper">
        <div className="user-wrapper">
          <div className="user" onClick={() => window.open(`/trader/${cuid}`)}>
            {" "}
            {/* nickname 알파노트_매뉴얼 nick 랭커_리그  */}
            {nickname || nick}
          </div>
          <UserInfo>
            {league + "리그"}
            <span>{rank !== -1 ? rank + "위" : "참가자"}</span>
            {" | "}
            {"투자스타일"}
            <span>{TRADE_STYLE_TYPE[style]}</span>
          </UserInfo>
          <UserDataInfo>
            <AlphaTag className="tag">{"대회정보"}</AlphaTag>{" "}
            <span className="underline">
              <span className="league">
                {leagueData?.broker + "증권 " + " " + leagueData?.name}
              </span>

              <span className="date">
                {formatDateToMD(start * 1000, 9) +
                  " - " +
                  formatDateToMD(end * 1000)}
              </span>
            </span>
            {on_going && <span className="in-league">대회중</span>}
          </UserDataInfo>
        </div>
        <div className="action">
          <ActionSection>
            <div className="round-button" onClick={() => openModal()}>
              <ShareIcon />
            </div>
          </ActionSection>
          <ShareModal
            isOpen={isOpen}
            onCancel={closeModal}
            url={window.location.href}
            setSnackOpen={setSnackOpen}
          />
        </div>
      </div>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={snackOpen}
        autoHideDuration={1000}
        onClose={handleSnackClose}
        message="클립보드에 복사되었습니다"
      />
    </UserInfoWrapper>
  );
};

const UserInfo = styled.div`
  font-family: Pretendard;

  margin-top: 10px;

  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  line-height: 17.9px;
  text-align: left;

  color: #888888;
  span {
    font-weight: 600;
    text-align: left;
    color: #7d7d7d;
    margin-left: 4px;
  }
`;
const UserDataInfo = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 14.32px;
  text-align: left;
  margin-top: 6px;

  .league {
    margin-left: 8px;
    color: #afb0b2;
  }
  .date {
    margin-left: 4px;
    color: #7d7e80;
  }
  .in-league {
    margin-left: 6px;
    font-weight: 400;
    color: #000000;
  }
  .tag {
    margin-left: 0;

    padding: 3px 6px;
  }

  .underline {
    text-decoration-style: dashed;
    text-decoration-line: underline;
    text-underline-position: under;
    color: #646566;
  }
`;
