// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  width: 100%;
  max-width: 100vw;  /* 뷰포트 너비를 넘지 않도록 */
  overflow-x: hidden; /* 항상 가로 스크롤 방지 */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 1240px) {
  .App {
    width: 100%;
  }
}

/* 태블릿 크기 */
@media screen and (max-width: 768px) {
  .App {
    font-size: 14px;
  }
}

/* 모바일 크기 */
@media screen and (max-width: 480px) {
  .App {
    font-size: 12px;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,gBAAgB,GAAG,mBAAmB;EACtC,kBAAkB,EAAE,iBAAiB;EACrC,SAAS;EACT,UAAU;EACV,sBAAsB;AACxB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;;AAGA;EACE;IACE,WAAW;EACb;AACF;;AAEA,WAAW;AACX;EACE;IACE,eAAe;EACjB;AACF;;AAEA,WAAW;AACX;EACE;IACE,eAAe;EACjB;AACF;AACA;EACE,sBAAsB;EACtB,SAAS;EACT,UAAU;AACZ","sourcesContent":[".App {\n  text-align: center;\n  width: 100%;\n  max-width: 100vw;  /* 뷰포트 너비를 넘지 않도록 */\n  overflow-x: hidden; /* 항상 가로 스크롤 방지 */\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n\n@media screen and (max-width: 1240px) {\n  .App {\n    width: 100%;\n  }\n}\n\n/* 태블릿 크기 */\n@media screen and (max-width: 768px) {\n  .App {\n    font-size: 14px;\n  }\n}\n\n/* 모바일 크기 */\n@media screen and (max-width: 480px) {\n  .App {\n    font-size: 12px;\n  }\n}\n* {\n  box-sizing: border-box;\n  margin: 0;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
