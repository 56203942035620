import { StockListCard } from "components/Card/StockListCard";
import { Tooltip } from "react-tooltip";
import { Area, AreaChart, CartesianGrid, Dot, XAxis, YAxis } from "recharts";
import styled from "styled-components";
import {
  addCommaToInt,
  convertToNearWon,
  convertToPercentage,
  convertToPnlRatio,
  convertToTwoDecimalPlaces,
  formatTimestampToMD,
} from "utils/utils";

const SummaryWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 360px);

  grid-template-rows: 157px 407px;

  gap: 24px;

  > div:nth-child(1) {
    grid-column: span 1;
    grid-row: span 1;
  }

  > div:nth-child(2) {
    grid-column: span 1;
    grid-row: span 1;
  }

  > div:nth-child(3) {
    grid-column: span 1;
    grid-row: span 2;
  }

  > div:nth-child(4) {
    grid-column: span 1;
    grid-row: span 1;
  }

  > div:nth-child(5) {
    grid-column: span 1;
    grid-row: span 1;
  }
`;

const CardWrapper = styled.div`
  border: 1px solid #ebedf5;
  background: #ffffff;
  border-radius: 8px;
  width: 360px;

  .p {
    color: #ff2727;
  }

  .n {
    color: #4c67ff;
  }
`;

const CardTitle = styled.div`
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.48px;
  text-align: left;
  color: #141414;

  .ct-tag {
    margin-left: 6px;
  }
`;

const PnlWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  gap: 20px;

  .pnl-info-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .pnl-info {
      display: flex;

      justify-content: space-between;
      font-family: Pretendard;
      font-size: 15px;
      font-weight: 400;
      line-height: 17.9px;
      text-align: left;
    }

    .pnl-name {
      color: #afb0b2;
    }
  }

  .ct-tag {
    padding: 4px 8px;
    border-radius: 100px;
    border: 1px solid #d5f2ca;
    background: #e7ffde;
    font-family: Pretendard;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.32px;
    text-align: left;
    color: #2b840c;
  }
  .tag-rank {
    margin-left: 2px;
    font-weight: 700;
  }
`;
const isMyNote = window.location.pathname === "/mynote";
const PnlCard = ({ title = "", ret, pnl, seed, n_rank, n_total }) => {
  return (
    <PnlWrapper>
      <CardTitle>
        {title}
        {!isMyNote && (
          <span className="ct-tag">
            {n_total + "명 중"}
            <span className="tag-rank">{n_rank + "위"}</span>
          </span>
        )}
      </CardTitle>
      <div className="pnl-info-wrapper">
        <div className="pnl-info">
          <div className="pnl-name">수익률</div>
          <div className={`pnl-value ${ret >= 0 ? "p" : "n"}`}>
            {convertToPercentage(ret)}
          </div>
        </div>
        <div className="pnl-info">
          <div className="pnl-name">수익금</div>
          <div className={`pnl-value`}>{convertToNearWon(pnl)}</div>
        </div>
        <div className="pnl-info">
          <div className="pnl-name">투자금</div>
          <div className={`pnl-value`}>{convertToNearWon(seed)}</div>
        </div>
      </div>
    </PnlWrapper>
  );
};

const TrxWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  gap: 20px;

  .trx-tab-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .trx-info-wrapper {
    width: 140px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .trx-info {
      display: flex;

      justify-content: space-between;
      font-family: Pretendard;
      font-size: 15px;
      font-weight: 400;
      line-height: 17.9px;
      text-align: left;
    }

    .trx-name {
      color: #afb0b2;
    }
  }

  .trx-line {
    border-left: 1px dashed #c8c9cc;
  }
`;
const TrxCard = ({
  title = "",
  pnl_ratio,
  win_rate,
  n_tr,
  turnover,
  amt,
  avg_n_stock,
}) => {
  return (
    <TrxWrapper>
      <CardTitle>{title}</CardTitle>
      <div className="trx-tab-wrapper">
        <div className="trx-info-wrapper">
          <div className="trx-info">
            <div className="trx-name">손익비</div>
            <div className={`trx-value ${pnl_ratio >= 0 ? "p" : "n"}`}>
              {convertToPnlRatio(pnl_ratio)}
            </div>
          </div>
          <div className="trx-info">
            <div className="trx-name">승률</div>
            <div className={`trx-value`}>{convertToPercentage(win_rate)}</div>
          </div>
          <div className="trx-info">
            <div className="trx-name">매매</div>
            <div className={`trx-value`}>{addCommaToInt(n_tr) + "건"}</div>
          </div>
        </div>
        <div className="trx-line" />
        <div className="trx-info-wrapper">
          <div className="trx-info">
            <div className="trx-name">회전율</div>
            <div className={`trx-value`}>{convertToPercentage(turnover)}</div>
          </div>
          <div className="trx-info">
            <div className="trx-name">거래대금</div>
            <div className={`trx-value `}>{convertToNearWon(amt)}</div>
          </div>
          <div className="trx-info">
            <div className="trx-name">평균거래</div>
            <div className={`trx-value`}>
              {convertToTwoDecimalPlaces(avg_n_stock) + "종목"}
            </div>
          </div>
        </div>
      </div>
    </TrxWrapper>
  );
};

const StockListCardWrapper = styled.div`
  box-sizing: border-box;
  padding: 20px;
`;

const PnlGraphCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  .graph-section {
    margin-top: 37px;
  }
`;

const StyledText = styled.text`
  fill: #fff;
  text-anchor: start;
  dominant-baseline: central;
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 600;
`;

const CustomLabel = ({ x, y, value, position = "center" }) => {
  const text = convertToPercentage(value);
  const isPositive = value >= 0;
  const backgroundColor = isPositive ? "#FF3D6C" : "#456DFA";
  const paddingX = 4;
  const paddingY = 2;
  const borderRadius = 4;

  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = "13px Pretendard";
  const textWidth = context.measureText(text).width;
  const textHeight = 15.5;

  const rectX =
    position === "center"
      ? x - textWidth / 2 - paddingX
      : position === "left"
      ? 40
      : 317 - (textWidth + paddingX);

  return (
    <g>
      <rect
        x={rectX}
        y={y - textHeight / 2 - paddingY - 14}
        width={textWidth + paddingX * 2}
        height={textHeight + paddingY * 2}
        fill={backgroundColor}
        rx={borderRadius}
        ry={borderRadius}
      />
      <StyledText
        x={rectX + 3}
        y={y - 14}
        $isPositive={isPositive}
        fill="#FFF"
        textAnchor="start"
      >
        {text}
      </StyledText>
    </g>
  );
};

const PnlGraphCard = ({ title = "", chart_ret }) => {
  const gradientOffset = () => {
    const dataMax = Math.max(...chart_ret.map((i) => i.ret));
    const dataMin = Math.min(...chart_ret.map((i) => i.ret));

    if (dataMax <= 0) {
      return 0;
    } else if (dataMin >= 0) {
      return 1;
    } else {
      return dataMax / (dataMax - dataMin);
    }
  };
  const off = gradientOffset();

  return (
    <PnlGraphCardWrapper>
      <CardTitle>{title}</CardTitle>
      <div className="graph-section">
        <AreaChart
          width={322}
          height={306}
          data={chart_ret?.map((value) => ({
            value: value.ret,
            date: formatTimestampToMD(value.date, "/"),
          }))}
        >
          <defs>
            <linearGradient id="ret" x1="0" y1="0" x2="0" y2="1">
              <stop offset={off} stopColor="#FF3D6C" />
              {off !== 1 && <stop offset={off} stopColor="#7291FF" />}
            </linearGradient>
            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FF5AA0" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#FF4A76" stopOpacity={0} />
            </linearGradient>

            <linearGradient id="positive" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset={0}
                stopColor="rgba(255, 77, 184, 0.14)"
                stopOpacity={1}
              />
              <stop offset={off} stopColor="#ffffff" stopOpacity={0.8} />
              <stop offset={"100%"} stopColor="#7291FF" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <Tooltip />
          <YAxis
            interval={"preserveStartEnd"}
            unit={"%"}
            width={40}
            padding={{ top: 10 }}
            axisLine={false}
            tickLine={false}
            dataKey={"value"}
            fill="#C8C9CC"
            tick={(props) => {
              const { x, y, payload } = props;
              return (
                <text
                  fontFamily="Pretendard"
                  fontSize={14}
                  fontWeight={400}
                  x={x}
                  y={y}
                  fill="#C8C9CC"
                  textAnchor="end"
                  dominantBaseline="middle"
                >
                  {payload.value}%
                </text>
              );
            }}
          />
          <XAxis
            dataKey="date"
            axisLine={false}
            tickLine={false}
            fill="#969799"
            tick={(props) => {
              const { x, y, payload } = props;
              return (
                <text
                  fontFamily="Pretendard"
                  fontSize={14}
                  fontWeight={400}
                  x={x}
                  y={y + 6}
                  fill="#969799"
                  textAnchor="middle"
                  dominantBaseline="middle"
                >
                  {payload.value}
                </text>
              );
            }}
          />
          <Area
            dataKey="value"
            stroke="url(#ret)"
            type="monotoneX"
            fill="url(#positive)"
            strokeWidth={2}
            baseLine={0}
            dot={({ cx, cy, index, value }) => {
              if (index === 0 || index === chart_ret.length - 1) {
                return (
                  <Dot
                    key={index}
                    cx={cx}
                    cy={cy}
                    r={2.5}
                    fill={value[1] >= 0 ? "#FF3D6C" : "#7291FF"}
                  />
                );
              }
              return null;
            }}
            label={({ x, y, index, value }) => {
              if (index === 0 || index === chart_ret.length - 1) {
                return (
                  <CustomLabel
                    key={index}
                    x={x}
                    y={y}
                    value={value}
                    position={
                      chart_ret.length === 1
                        ? "center"
                        : index === 0
                        ? "left"
                        : "right"
                    }
                  />
                );
              }
              return null;
            }}
          />
        </AreaChart>
      </div>
    </PnlGraphCardWrapper>
  );
};

const VolumeCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  gap: 16px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;
  text-align: left;

  border-radius: 4px;
`;

const TableRow = styled.tr`
  border: 1px solid #e7e9ef;
  height: 30px;
`;

const TableCell = styled.td`
  border: 1px solid #e7e9ef;
  position: relative;

  &:first-child {
    width: 35px;
    padding-right: 12px;
    text-align: right;
  }
`;

const TableHeadCell = styled.td`
  background: #f2f4f7;
  padding-right: 12px;
  text-align: right;
  &:first-child {
    width: 48px;
    text-align: center;
    padding-right: 0;
  }
`;

const TableLable = styled.div`
  z-index: 2;
  position: absolute;

  right: 12px;
  top: 0;
  display: flex;
  height: 100%;

  justify-content: end;
  align-items: center;

  color: #4b4c4d;
`;

const BarItem = styled.div`
  z-index: 1;
  position: absolute;
  width: ${(props) => props.width}%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.5;
  background: linear-gradient(to right, #ffffff, #ffbfcf);
  transition: width 0.5s ease-in-out;
`;

const VolumeCard = ({ title = "", timeData = {} }) => {
  const calcWidth = (value, max) => {
    return (value / max) * 100;
  };

  function setValue(number) {
    return number === "" ? "-" : addCommaToInt(number);
  }
  const convertTime = (time) => {
    const hour = parseInt(time.split(":")[0]);
    return hour;
  };

  const buysMax = timeData
    ? Math.max(...timeData?.map((item) => item?.amt_buy))
    : 0;
  const sellsMax = timeData
    ? Math.max(...timeData?.map((item) => item?.amt_sell))
    : 0;

  return (
    <VolumeCardWrapper>
      <CardTitle>{title}</CardTitle>
      <Table>
        <thead>
          <TableRow>
            <TableHeadCell>시간</TableHeadCell>
            <TableHeadCell>매수 금액</TableHeadCell>
            <TableHeadCell>매도 금액</TableHeadCell>
          </TableRow>
        </thead>
        <tbody>
          {timeData?.map((item, index) => (
            <TableRow key={index}>
              <TableCell>{convertTime(item.time)}</TableCell>
              <TableCell>
                <BarItem width={calcWidth(item.amt_buy, buysMax)} />
                <TableLable>
                  {setValue(convertToNearWon(item.amt_buy))}
                </TableLable>
              </TableCell>
              <TableCell>
                <BarItem width={calcWidth(item.amt_sell, sellsMax)} />
                <TableLable>
                  {setValue(convertToNearWon(item.amt_sell))}
                </TableLable>
              </TableCell>
            </TableRow>
          ))}
        </tbody>
      </Table>
    </VolumeCardWrapper>
  );
};

const InfoSummarySection = ({ data, lastUpdate = "" }) => {
  const {
    n_rank,
    n_total,
    ret,
    est_pnl,
    est_seed,
    chart_ret,
    pnl_ratio,
    win_rate,
    n_tr,
    turnover,
    amt,
    avg_n_stock,
    table_amt,
  } = data?.summary || {};

  const { stock } = data;

  return (
    <SummaryWrapper>
      <CardWrapper>
        <PnlCard
          title="성과 분석"
          ret={ret}
          pnl={est_pnl}
          seed={est_seed}
          n_rank={n_rank}
          n_total={n_total}
        />
      </CardWrapper>
      <CardWrapper>
        <TrxCard
          title="거래 분석"
          pnl_ratio={pnl_ratio}
          win_rate={win_rate}
          n_tr={n_tr}
          turnover={turnover}
          amt={amt}
          avg_n_stock={avg_n_stock}
        />
      </CardWrapper>
      <CardWrapper>
        <StockListCardWrapper>
          <StockListCard
            minHeight={360}
            data={stock}
            slinenum={8}
            last_update={lastUpdate}
          />
        </StockListCardWrapper>
      </CardWrapper>
      <CardWrapper>
        <PnlGraphCard title="수익률 추이" chart_ret={chart_ret} />
      </CardWrapper>
      <CardWrapper>
        <VolumeCard title="시간대별 거래대금" timeData={table_amt} />
      </CardWrapper>
    </SummaryWrapper>
  );
};

export default InfoSummarySection;
