import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useAuth from "utils/auth";
import pushEvent from "utils/event";

const LoginComplete = () => {
  const { access_token } = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState("로그인 처리 중...");
  const { setToken } = useAuth();
  const [eventPushed, setEventPushed] = useState(false);

  useEffect(() => {
    if (!access_token) {
      setMessage("로그인 실패. 다시 시도해주세요.");
      setTimeout(() => navigate("/login"), 3000);
      return;
    }

    const redirectAfterLogin =
      localStorage.getItem("redirectAfterLogin") || "/";
    const finalRedirect =
      redirectAfterLogin === "/logout" ? "/" : redirectAfterLogin;

    const payload = setToken(access_token);

    if (!payload) {
      setMessage("로그인 처리 중 오류가 발생했습니다.");
      setTimeout(() => navigate("/login"), 3000);
      return;
    }

    setMessage("로그인 성공!");
    if (!eventPushed) {
      pushEvent("login", payload.id);
      setEventPushed(true);
    }

    setTimeout(() => {
      if (window.opener) {
        // window.opener.location.reload();
        window.close();
      } else {
        navigate(finalRedirect);
      }
    }, 1000);
  }, [access_token, navigate, setToken]);

  return (
    <div>
      <h2>{message}</h2>
    </div>
  );
};

export default LoginComplete;
