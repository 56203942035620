import { useQuery, useMutation, useQueryClient } from "react-query";
import useHttpClient, { api_v_url } from "hooks/useHttpClient";

export const QUERY_KEYS = {
  POSTS: "board-posts",
  COMMENTS: "board-comments",
};

const BOARD_API = {
  GET_POSTS: "/v1b/board/",
  CREATE_POST: "/v1b/board/write",
  CREATE_COMMENT: "/v1b/board/comment",
  DELETE_POST: "/v1b/board/delete",
  LIKE_POST: "/v1b/board/like",
  UPDATE_POST: "/v1b/board/update",
};

// 게시글 목록 조회
export function useGetPosts(pageNumber = 1, pageSize = 10) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery(
    [QUERY_KEYS.POSTS, pageNumber, pageSize],
    async () => {
      const { data } = await httpClient.get(BOARD_API.GET_POSTS, {
        params: {
          bid: "free",
          page: pageNumber,
          size: pageSize,
        },
      });
      return data;
    },
    {
      staleTime: 60 * 1000,
      keepPreviousData: true,
      retry: 1,
      refetchInterval: false,
      refetchOnWindowFocus: true,
    }
  );
}

// 게시글 작성
export function useCreatePost() {
  const httpClient = useHttpClient(api_v_url);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ content, nickname = "", password = "" }) => {
      const { data } = await httpClient.post(BOARD_API.CREATE_POST, null, {
        params: {
          bid: "free",
          content,
          nickname,
          password,
        },
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(QUERY_KEYS.POSTS);
      },
      onError: (error) => {
        if (error.response?.status === 401) {
          alert("로그인이 필요합니다.");
        } else {
          alert("게시글 작성에 실패했습니다.");
        }
        console.error("Post creation failed:", error);
      },
    }
  );
}

// 댓글 작성
export function useCreateComment() {
  const httpClient = useHttpClient(api_v_url);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, content, nickname = "", password = "" }) => {
      const { data } = await httpClient.post(BOARD_API.CREATE_COMMENT, null, {
        params: { id, content, nickname, password },
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.POSTS);
      },
      onError: (error) => {
        if (error.response?.status === 401) {
          alert("로그인이 필요합니다.");
        } else {
          alert("댓글 작성에 실패했습니다.");
        }
        console.error("Comment creation failed:", error);
      },
    }
  );
}

// 게시글 삭제
export function useDeletePost() {
  const httpClient = useHttpClient(api_v_url);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, password = "" }) => {
      const { data } = await httpClient.post(BOARD_API.DELETE_POST, null, {
        params: { id, password },
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries(QUERY_KEYS.POSTS);
      },
      onError: (error) => {
        if (error.response?.status === 403) {
          alert("삭제 권한이 없습니다.");
        } else {
          alert("게시글 삭제에 실패했습니다.");
        }
        console.error("Post deletion failed:", error);
      },
    }
  );
}

// 좋아요
export function useLikePost() {
  const httpClient = useHttpClient(api_v_url);
  const queryClient = useQueryClient();

  return useMutation(
    async (id) => {
      const { data } = await httpClient.post(BOARD_API.LIKE_POST, null, {
        params: { id },
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.POSTS);
      },
      onError: (error) => {
        if (error.response?.status === 401) {
          alert("로그인이 필요합니다.");
        }
        console.error("Like action failed:", error);
      },
    }
  );
}

// UpdatePost mutation
export function useUpdatePost() {
  const httpClient = useHttpClient(api_v_url);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, content }) => {
      const { data } = await httpClient.post(BOARD_API.UPDATE_POST, null, {
        params: {
          bid: "free",
          pid: id,
          content,
        },
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.POSTS);
      },
      onError: (error) => {
        if (error.response?.status === 401) {
          alert("로그인이 필요합니다.");
        } else if (error.response?.status === 403) {
          alert("수정 권한이 없습니다.");
        } else {
          alert("게시글 수정에 실패했습니다.");
        }
        console.error("Post update failed:", error);
      },
    }
  );
}
