import { useState, useEffect } from "react";
import base64 from "base-64";

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const setToken = (token) => {
    try {
      const payload = JSON.parse(base64.decode(token.split(".")[1]));
      localStorage.setItem("access_token", token);
      setIsLoggedIn(true);
      setCurrentUser({
        id: payload.id,
        nickname: payload.nickname,
      });

      return payload;
    } catch (error) {
      console.error("Token processing error:", error);
      return null;
    }
  };

  const checkTokenValidity = () => {
    const token = localStorage.getItem("access_token");
    if (token) {
      try {
        const payload = JSON.parse(base64.decode(token.split(".")[1]));
        const currentTime = Date.now() / 1000;

        if (payload.exp < currentTime) {
          logout();
          return false;
        } else {
          setIsLoggedIn(true);
          setCurrentUser({
            id: payload.id,
            nickname: payload.nickname,
          });
          return true;
        }
      } catch (error) {
        console.error("Token validation error:", error);
        logout();
        return false;
      }
    }
    logout();
    return false;
  };

  const logout = () => {
    localStorage.removeItem("access_token");
    setIsLoggedIn(false);
    setCurrentUser(null);
  };

  useEffect(() => {
    checkTokenValidity(false); // 초기 토큰 체크

    // 10초마다 토큰 유효성 체크
    const tokenCheckInterval = setInterval(() => {
      checkTokenValidity(false);
    }, 10000);

    // 다른 탭에서의 로그인/로그아웃 감지
    const handleStorageChange = (e) => {
      if (e.key === "access_token") {
        checkTokenValidity(false);
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      clearInterval(tokenCheckInterval);
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return {
    isLoggedIn,
    currentUser,
    setToken,
    checkTokenValidity,
    logout,
  };
};

export default useAuth;
