import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import useHttpClient from "hooks/useHttpClient";

export const QUERY_KEYS = {
  MEMO: "alpha-note-memo",
};

export function useGetMemos(cuid, code) {
  const httpClient = useHttpClient();

  return useInfiniteQuery(
    [QUERY_KEYS.MEMO, cuid, code],
    async ({ pageParam = 0 }) => {
      const { data } = await httpClient.get("/note/memo", {
        params: {
          cuid,
          code,
          position: pageParam,
          n: 20,
        },
      });
      return data;
    },
    {
      getNextPageParam: (lastPage) =>
        lastPage.data.finished ? undefined : lastPage.data.position,
      staleTime: 60 * 1000,
    }
  );
}

export function useCreateMemo() {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();

  return useMutation(
    async (memoData) => {
      const { cuid, code, memo } = memoData;
      const { data } = await httpClient.post("/note/memo", null, {
        params: {
          cuid,
          code,
          memo,
        },
      });
      return data;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          QUERY_KEYS.MEMO,
          variables.cuid,
          variables.code,
        ]);
      },
      onError: (error) => {
        console.error("Memo creation failed:", error);
        if (error.response?.status === 401) {
          alert("로그인이 필요합니다.");
        } else {
          alert("메모 작성에 실패했습니다.");
        }
      },
    }
  );
}

export function useUpdateMemo() {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();

  return useMutation(
    async (memoData) => {
      const { cuid, code, memo, _id } = memoData;
      const { data } = await httpClient.post("/note/memo", null, {
        params: {
          cuid,
          code,
          memo,
          _id,
        },
      });
      return data;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          QUERY_KEYS.MEMO,
          variables.cuid,
          variables.code,
        ]);
      },
      onError: (error) => {
        console.error("Memo update failed:", error);
        if (error.response?.status === 401) {
          alert("로그인이 필요합니다.");
        } else {
          alert("메모 수정에 실패했습니다.");
        }
      },
    }
  );
}

export function useDeleteMemo() {
  const httpClient = useHttpClient();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ _id, cuid, code }) => {
      const { data } = await httpClient.delete("/note/memo", {
        params: { _id },
      });
      return data;
    },
    {
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries([
          QUERY_KEYS.MEMO,
          variables.cuid,
          variables.code,
        ]);
      },
      onError: (error) => {
        console.error("Memo deletion failed:", error);
        if (error.response?.status === 401) {
          alert("로그인이 필요합니다.");
        } else {
          alert("메모 삭제에 실패했습니다.");
        }
      },
    }
  );
}
