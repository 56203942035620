import moment from "moment";

function createMockData() {
  const generateChartValues = () => {
    const values = [];
    const length = Math.floor(Math.random() * 10) + 10; // Generates between 10 and 20 chart values
    for (let i = 0; i < length; i++) {
      values.push(parseFloat((Math.random() * 5).toFixed(2)));
    }
    return values;
  };

  const leagues = ["1억", "5천", "3천", "1천", "1백"];
  const randomLeagueIndex = Math.floor(Math.random() * leagues.length);
  const randomNumber = Math.floor(Math.random() * 1000);
  const mockData = {
    nickname: "닉네임" + randomNumber,
    league: leagues[randomLeagueIndex],
    trading_style: "10분",
    return_ratio: `${parseFloat(Math.random() * 100).toFixed(2)}%`,
    return: `${parseInt(Math.random() * 10000000)}원`,
    date: new Date().toISOString().slice(0, 10).replace(/-/g, "."),
    win_ratio: `${parseInt(Math.random() * 100)}%`,
    stock_code: "328130",
    stock_name: "루닛",
    n_trade: `${parseInt(Math.random() * 20) + 1}회`,
    chart: generateChartValues(),
    league_rank: parseInt(Math.random() * 100),
  };

  return mockData;
}

function addCommaToInt(number) {
  if (!number) return "";
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const convertToPercentage = (decimal) => {
  if (decimal === 0) return "0%";
  if (!decimal) return "";
  return `${decimal.toFixed(2)}%`;
};

const convertToPnlRatio = (number) => {
  if (!number) return "평가 중";
  if (number > 50) return "매우 높음";
  else if (number <= 0) return "0 : 1";
  else return `${number.toFixed(2)} : 1`;
};

const convertToWon = (number) => {
  if (!number) return "";
  return `${number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원`;
};

const convertToNearWon = (number) => {
  // 숫자를 화폐 단위로 변환하며 근사치를 적용한다.
  // 음수의 경우 양수로 변환하여 계산하고, 마지막에 음수 부호를 붙인다.
  // 1만원 이하 -> 소수점 없이 화폐 단위로 표기
  // 1억원 미만 -> 천원 이하 단위 버림, 화폐 단위로 표기
  // 1억 이상 -> 백만원 이하 단위 버림, 화폐 단위로 표기

  number = parseFloat(number);
  var text = "";
  if (!number) return "";
  var isNegative = number < 0;

  if (isNegative) {
    number = Math.abs(number);
  }

  // if (number > 1000000000000) {
  //   number = `${(number / 1000000000000).toFixed(1)}`;
  //   number = number.replace(".0", "");
  //   text = `조원`
  // }
  if (number >= 100000000) {
    number = `${(number / 100000000).toFixed(1)}`;
    number = number.replace(".0", "");
    text = `억원`;
  } else if (number >= 10000) {
    number = `${Math.floor(number / 10000).toFixed(0)}`;
    text = `만원`;
  } else {
    number = `${Math.floor(number).toFixed(0)}`;
    text = `원`;
  }

  number = number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return isNegative ? `-${number}${text}` : `${number}${text}`;
};

const convertToPlusWon = (number) => {
  number = parseFloat(number);

  if (!number) return "추정 중";

  return number > 0
    ? `+${number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원`
    : `${number.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}원`;
};

const convertToTwoDecimalPlaces = (number) => {
  if (!number) return "";
  return number.toFixed(2);
};

const formatSec = (sec) => {
  const D = Math.floor(sec / (3600 * 24));
  const H = Math.floor((sec % (3600 * 24)) / 3600);
  const M = Math.floor((sec % 3600) / 60);
  const S = (sec % 60).toFixed(0);

  if (D > 0) {
    return H > 0 ? `${D}d ${H}h` : `${D}d`;
  } else if (H > 0) {
    return M > 0 ? `${H}h ${M}m` : `${H}h`;
  } else if (M > 0) {
    return S > 0 ? `${M}m ${S}s` : `${M}m`;
  } else {
    return `${S}s`;
  }
};

// const formatDayTime = (dateString) => {
//   const date = new Date(dateString);
//   const hours = date.getHours().toString().padStart(2, "0");
//   const minutes = date.getMinutes().toString().padStart(2, "0");
//   // const seconds = date.getSeconds().toString().padStart(2, "0");
//   // return `${hours}:${minutes}:${seconds}`;
//   return `${hours}:${minutes}`;
// };

const formatDayTime = (timestamp, showDate = true) => {
  const date = new Date(timestamp * 1000); // convert to milliseconds
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0"); // months are 0-based in JavaScript
  const day = date.getUTCDate().toString().padStart(2, "0");
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  if (showDate) return `${month}-${day} ${hours}:${minutes}`;
  else return `${hours}:${minutes}`;
};

function formatDateToYMD(date) {
  if (!date) return "";
  return moment(date).format("yyyy-MM-DD");

  const year = date.getFullYear(); // Extracts the year
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Extracts the month and adds leading zero if necessary
  const day = String(date.getDate()).padStart(2, "0"); // Extracts the day and adds leading zero if necessary

  return `${year}-${month}-${day}`; // Concatenates and returns the formatted date
}

function formatTimestampToYMD(ts, tz = 9) {
  if (!ts) return "";

  const date = new Date(ts * 1000); // convert to milliseconds
  date.setHours(date.getHours() - tz); // convert to local time

  const year = date.getUTCFullYear(); // Extracts the year
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Extracts the month and adds leading zero if necessary
  const day = String(date.getUTCDate()).padStart(2, "0"); // Extracts the day and adds leading zero if necessary

  return `${year}-${month}-${day}`; // Concatenates and returns the formatted date
}

function formatTimestampToKoYMD(timestamp) {
  const date = new Date(timestamp * 1000); // convert to milliseconds

  const year = date.getUTCFullYear(); // Extracts the year
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Extracts the month and adds leading zero if necessary
  const day = String(date.getUTCDate()).padStart(2, "0"); // Extracts the day and adds leading zero if necessary

  return `${year}년 ${month}월 ${day}일`; // Concatenates and returns the formatted date
}

function unescapeHtml(str) {
  if (!str) return "";
  return str.replace(/&amp;/g, "&");
}

function getPostposition(word) {
  if (!word) return "";
  const lastChar = word[word.length - 1];
  const lastCharCode = lastChar.charCodeAt(0);
  const isConsonant = (lastCharCode - 44032) % 28 > 0;
  return isConsonant ? "을" : "를";
}

function formatDateTime(str) {
  if (!str) return "";
  return moment(str).format("yyyy-MM-DD HH:mm:ss");
}

function formatDateToMD(str, addHours = 0) {
  if (!str) return "";
  return moment(str).add(addHours, "hours").format("MM.DD");
}

function formatTimestampToMD(timestamp, c = ".") {
  const date = new Date(timestamp * 1000); // convert to milliseconds
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Extracts the month and adds leading zero if necessary
  const day = String(date.getUTCDate()).padStart(2, "0"); // Extracts the day and adds leading zero if necessary
  return `${month}${c}${day}`; // Concatenates and returns the formatted date
}

function formatSeconds(sec) {
  const seconds = parseInt(sec);
  if (seconds >= 86400) {
    // 1일 = 86400초
    let days = (seconds / 86400).toFixed(1);
    return `${days}일`;
  } else if (seconds >= 3600) {
    // 1시간 = 3600초
    let hours = Math.floor(seconds / 3600);
    let minutes = Math.floor((seconds % 3600) / 60);
    return `${hours}시간 ${minutes}분`;
  } else if (seconds >= 60) {
    // 1분 = 60초
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;
    return `${minutes}분 ${remainingSeconds}초`;
  } else {
    return `${seconds}초`;
  }
}

function convertSeconds(seconds) {
  const days = Math.floor(seconds / (24 * 60 * 60));
  seconds %= 24 * 60 * 60;
  const hours = Math.floor(seconds / (60 * 60));
  seconds %= 60 * 60;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  if (days > 0) return `${days.toFixed(1)}일`;
  else if (hours > 0) return `${hours}시간 ${minutes}분`;
  else if (minutes > 0) return `${minutes}분 ${seconds}초`;
  else return `${seconds}초`;
}

const formatDate = (
  timestamp,
  hasYear = false,
  isTimeStamp = false,
  hasTime = false
) => {
  const date = isTimeStamp ? new Date(timestamp * 1000) : new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const hour = date.getHours();
  const minute = date.getMinutes();

  return `${hasYear ? year + "년 " : ""}${month}월 ${day}일${
    hasTime ? ` ${hour}시 ${minute}분` : ""
  }`;
};

const convertEmoji = (text) => {
  if (!text) return "";
  return text.replace(/\\u{([0-9a-f]{4,})}/i, (_, p1) =>
    String.fromCodePoint(parseInt(p1, 16))
  );
};

const formatTimeAgo = (dateString) => {
  const date = new Date(dateString * 1000);
  const now = new Date();
  const diffInSeconds = Math.floor((now - date) / 1000);

  if (diffInSeconds < 60) {
    return "방금 전";
  }

  const diffInMinutes = Math.floor(diffInSeconds / 60);
  if (diffInMinutes < 60) {
    return `${diffInMinutes}분 전`;
  }

  const diffInHours = Math.floor(diffInMinutes / 60);
  if (diffInHours < 24) {
    return `${diffInHours}시간 전`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 7) {
    return `${diffInDays}일 전`;
  }

  const diffInWeeks = Math.floor(diffInDays / 7);
  if (diffInWeeks < 4) {
    return `${diffInWeeks}주 전`;
  }

  const diffInMonths = Math.floor(diffInDays / 30);
  if (diffInMonths < 12) {
    return `${diffInMonths}개월 전`;
  }

  const diffInYears = Math.floor(diffInDays / 365);
  return `${diffInYears}년 전`;
};

export {
  addCommaToInt,
  convertEmoji,
  convertSeconds,
  convertToNearWon,
  convertToPercentage,
  convertToPlusWon,
  convertToPnlRatio,
  convertToTwoDecimalPlaces,
  convertToWon,
  createMockData,
  formatDate,
  formatDateTime,
  formatDateToMD,
  formatDateToYMD,
  formatDayTime,
  formatSec,
  formatSeconds,
  formatTimeAgo,
  formatTimestampToYMD,
  formatTimestampToKoYMD,
  formatTimestampToMD,
  getPostposition,
  unescapeHtml,
};
