import axios from "axios";

// import { API_URL } from "../../shared/config";

// const api_url = "http://121.134.241.229:9000/api/v1/";
// const api_url = "https://api.alphanote.io/api/v1/";
const api_url = "https://api.alphanote.io/api/v1b/";

export const api_v_url = "https://api.alphanote.io/api/";

export default function useHttpClient(baseURL = api_url) {
  //   const state = useAuthStore((state) => state);
  //   if (!state.authProviderUsed) {
  //     throw new Error("useHttpClient는 AuthProvider와 함께 사용되어야 합니다.");
  //   }

  // localStorage.setItem(
  //   "access_token",
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjY2OGJlMGYxNTA5M2U2MDZjMWViNjVlNyIsImVtYWlsIjoidGVzdEBrYWthby5jb20iLCJleHAiOjIwMDAwMDAwMDB9.mjAHk099NukZvKBChTbTYe8eIWKUfzNRT_oSE5_a0nI"
  // );

  const access_token = localStorage.getItem("access_token") || "";
  // console.log("access_token", access_token);

  // const httpClient = axios.create({
  //   baseURL,
  //   headers: {
  //     Authorization: `Bearer ${access_token}`,
  //   },
  // });

  const httpClient = axios.create({
    baseURL,
    headers: { Accept: "application/json" },
  });
  httpClient.interceptors.request.use((config) => {
    config.headers.Authorization = `Bearer ${access_token}`;

    if (config.data instanceof FormData) {
      delete config.headers["Content-Type"];
    }

    return config;
  });
  //   httpClient.interceptors.response.use(
  //     (response) => response,
  //     async (error) => {
  //       if (error.response.status === 401) {
  //         const result = await refreshToken();
  //         if (result && state.token !== result) {
  //           state.setToken(result);
  //         }
  //       }
  //       return Promise.reject(error);
  //     }
  //   );
  return httpClient;
}

// function api_axios(method, path, data, retry = 0) {
//   const access_token = localStorage.getItem("access");

//   return axios({
//     method: method,
//     url: api_url + path,
//     headers: access_token
//       ? {
//           Authorization: "Bearer " + localStorage.getItem("access"),
//         }
//       : {},
//     data,
//   })
//     .then((response) => {
//       return response.data;
//     })
//     .catch(async (error) => {
//       if (!error.response) {
//         throw error;
//       }
//     });
// }
