const pushEvent = (eventName, userId = undefined) => {
  const targetWindow = window.opener || window.parent || window;
  targetWindow.dataLayer = targetWindow.dataLayer || [];

  if (userId) {
    targetWindow.dataLayer.push({
      event: eventName,
      user_id: userId,
      arpa_id: userId,
    });
    return;
  }

  targetWindow.dataLayer.push({
    event: eventName,
  });
  return;
};

export default pushEvent;
