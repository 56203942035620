import SVGIllustrator1 from "pages/Home/svg/Main.svg";

import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

// SVG 래퍼 컴포넌트 추가
const SVGWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 1600px;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }

  .clickable-area-container {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .clickable-area {
    position: absolute;

    transition: all 0.2s ease;

    &:hover {
      cursor: pointer;
    }
  }
`;

const BUTTON_AREAS = [
  {
    route: "/mynote",
    label: "매매일지 작성하러 가기",
    style: {
      top: "4.5%",
      left: "41.5%",
      width: "18%",
      height: ".65%",
    },
  },
  {
    route: "/trader",
    label: "추천 매매일지 확인하기",
    style: {
      top: "87.57%",
      left: "24.5%",
      width: "14.55%",
      height: "0.8%",
    },
  },
  {
    route: "https://www.arpa.co.kr/a15cd364395f4ef18b1f0d8a7e2f3a89",
    label: "매매내역 다운로드",
    style: {
      top: "93.7%",
      left: "25.0%",
      width: "25.0%",
      height: "1.25%",
    },
  },
  {
    route: "https://www.arpa.co.kr/158ace638ed6805eb7e9e9fa1899f764",
    label: "다른 증권사 올리고 싶어요",
    style: {
      top: "93.7%",
      left: "50.5%",
      width: "25.0%",
      height: "1.25%",
    },
  },
  // -
  {
    route:
      "https://www.arpa.co.kr/1fcd387cf6574b6cb9a2ef24b04fe027?v=90ad733c2bbe49a9bd6b3726efc465e3",
    label: "알파노트 제대로 활용하기",
    style: {
      top: "95.1%",
      left: "25.0%",
      width: "25.0%",
      height: "1.25%",
    },
  },
  {
    route: "https://www.arpa.co.kr/8827d622067a45259cae9fc122719dfc",
    label: "알파노트를 시작하게 된 이야기",
    style: {
      top: "95.1%",
      left: "50.5%",
      width: "25.0%",
      height: "1.25%",
    },
  },
  {
    route: "/mynote",
    label: "매매일지 작성하러 가기",
    style: {
      top: "97.30%",
      left: "41.5%",
      width: "18%",
      height: ".65%",
    },
  },
];

const LandingTradingJournal = () => {
  document.title = `알파노트 - 홈`;
  const navigate = useNavigate();

  const handleClick = (route) => {
    if (route.startsWith("http")) {
      // For external URLs, open in a new tab
      window.open(route, "_blank", "noopener noreferrer");
    } else {
      // For internal routes, use the navigate function
      navigate(route);
    }
  };

  return (
    <Wrapper>
      <SVGWrapper>
        <img src={SVGIllustrator1} alt="illustrator1" />
        <div className="clickable-area-container">
          {BUTTON_AREAS.map((area, index) => (
            <div
              key={index}
              className="clickable-area"
              style={area.style}
              onClick={() => handleClick(area.route)}
              title={area.label}
              role="button"
              aria-label={area.label}
              tabIndex={0}
            />
          ))}
        </div>
      </SVGWrapper>
    </Wrapper>
  );
};

export default LandingTradingJournal;
