import useHttpClient, { api_v_url } from "hooks/useHttpClient";
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";

function useAlphaTraderDetailQuery({ cuid = "", isMyNote }) {
  const httpClient = useHttpClient(api_v_url);
  return useQuery({
    queryKey: ["trader-detail", { cuid }],
    queryFn: async () => {
      if (!cuid) return;
      if (isMyNote) return;
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/trader?cuid=${cuid}`
      );
      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,

    retry: (failureCount, error) => {
      // 401 에러인 경우 재시도하지 않음
      if (error?.response?.status === 401) return false;
      // 다른 에러는 기본 재시도 정책 따름 (3번까지)
      return failureCount < 3;
    },
    retryOnMount: false,
  });
}

function useAlphaMyTraderQuery({ isMyNote }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["my-trader"],
    queryFn: async () => {
      if (!isMyNote) return;
      const { data: queryResult } = await httpClient.get(`/v1b/my/trader`);

      return queryResult?.data;
    },
    staleTime: 60 * 60 * 1000,

    retry: (failureCount, error) => {
      // 401 에러인 경우 재시도하지 않음
      if (error?.response?.status === 401) return false;
      // 다른 에러는 기본 재시도 정책 따름 (3번까지)
      return failureCount < 3;
    },
    retryOnMount: false,
  });
}

function useAlphaDetailHomeSummaryQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-home_summary", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/home_summary?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailHomeNoteRecentQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  const priority = "recent";
  return useQuery({
    queryKey: ["trader-home_note", { cuid, priority }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/home_note?cuid=${cuid}&sort_priority=${priority}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailHomeNoteRetQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  const priority = "ret";
  return useQuery({
    queryKey: ["trader-home_note", { cuid, priority }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/home_note?cuid=${cuid}&sort_priority=${priority}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailHomeNoteLossQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  const priority = "loss";
  return useQuery({
    queryKey: ["trader-home_note", { cuid, priority }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/home_note?cuid=${cuid}&sort_priority=${priority}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailHomeDailyGanttQuery({ cuid, date }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-home_daily_gantt ", { cuid, date }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/home_daily_gantt?cuid=${cuid}&date=${date}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid && !!date,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailHomeDailyGanttDateListQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-home_daily_gantt_datelist", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/home_daily_gantt_datelist?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailInfoSummaryQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-detail_summary ", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/detail_summary?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailInfoStyleQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-detail_style", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/detail_style?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailInfoChartQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-detail_chart", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/detail_chart?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailStockInfoQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-detail_basic", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/detail_basic?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAlphaDetailStockNoteQuery({
  cuid,
  code,
  sort_priority,
  sort_asc,
  position,
  styler_filter,
}) {
  const httpClient = useHttpClient(api_v_url);

  // cuid: cuid,
  // code: selectedStock,
  // sort_priority: priority,
  // sort_asc: sortAsc,
  // position: position,

  // return useQuery({
  //   queryKey: [
  //     "trader-detail_note",
  //     { cuid, code, sort_priority, sort_asc, position },
  //   ],
  //   queryFn: async () => {
  //     const { data: queryResult } = await httpClient.get(
  //       `/v1b/report/detail_note?cuid=${cuid}&code=${code}${
  //         sort_priority ? `&sort_priority=${sort_priority}` : ""
  //       }${sort_asc ? `&sort_asc=${sort_asc}` : ""}${
  //         position ? `&position=${position}` : ""
  //       }`
  //     );

  //     return queryResult?.data;
  //   },
  //   enabled: !!cuid,
  //   staleTime: 60 * 60 * 1000,
  // });

  return useInfiniteQuery({
    queryKey: [
      "trader-detail_note",
      { cuid, code, sort_priority, sort_asc, position, styler_filter },
    ],
    queryFn: async ({ pageParam }) => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/detail_note?cuid=${cuid}&code=${code}${
          sort_priority ? `&sort_priority=${sort_priority}` : ""
        }${sort_asc ? `&sort_asc=${sort_asc}` : ""}${
          pageParam ? `&position=${pageParam}` : ""
        }${styler_filter ? `&style_filter=${styler_filter}` : ""}`
      );

      return queryResult?.data;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPage) => {
      return lastPage.finished ? undefined : lastPage.position + 1;
    },
  });
}

function useAlphaDetailStockListQuery({ cuid }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["trader-detail_stock", { cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/report/detail_stock?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useTraderDateStockQuery(userId) {
  const httpClient = useHttpClient();

  return useQuery({
    queryKey: ["trader-analysis_date-stock_change", userId],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_date/stock_change?year=123&competition=123&cuid=${userId}`
      );

      return queryResult?.data;
    },
  });
}

function useAnalysisSummaryPerformanceQuery({ cid, cuid }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_summary_performance", { cid, cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_summary/performance?cid=${cid}&cuid=${cuid}`
      );
      return queryResult?.data;
    },
    // enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAnalysisSummaryHoldingTimePerformanceQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_summary_holding_time_performance"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_summary/holding_time_performance`
      );
      return queryResult?.data;
    },
  });
}

function useAnalysisDatePerformanceQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_date_performance"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_date/performance`
      );
      return queryResult?.data;
    },
  });
}

function useAnalysisDateHistoryQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_date_history"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_date/history`
      );
      return queryResult?.data;
    },
  });
}

function useAnalysisDateStockChangeQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_date_stock_change"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_date/stock_change`
      );
      return queryResult?.data;
    },
  });
}

function useAnalysisStockPerformanceQuery({ cid, cuid }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_stock_performance", { cid, cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_stock/performance?cid=${cid}&cuid=${cuid}`
      );
      return queryResult?.data;
    },
    // enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useAnalysisStockHistoryQuery() {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_stock_history"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_stock/history`
      );
      return queryResult?.data?.stocks;
    },
  });
}

function useAnalysisStockDailyQuery({ cid, cuid }) {
  const httpClient = useHttpClient();
  return useQuery({
    queryKey: ["trader-analysis_stock_daily", { cid, cuid }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/trader/analysis_stock/each?cid=${cid}&cuid=${cuid}`
      );
      return queryResult?.data;
    },
    // enabled: !!cid,
    staleTime: 60 * 60 * 1000,
  });
}

function useMyNoteAccountInfo() {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["my-account_info"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/my/account_info`
      );

      return queryResult?.data;
    },

    staleTime: 60 * 60 * 1000,
  });
}

function useUpdateMyNoteAccointInfo() {
  const httpClient = useHttpClient(api_v_url);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ nickname = "", account = "" }) => {
      const { data: queryResult } = await httpClient.post(
        `/v1b/my/account_info?nickname=${nickname}&account=${account}`,
        {}
      );

      return queryResult?.data;
    },
    {
      mutationKey: ["my-account_info"],
      staleTime: 60 * 60 * 1000,
      onSuccess: () => {
        queryClient.invalidateQueries("my-account_info");
      },
    }
  );
}

function useMyNoteTradeSnapshot({ cuid = "", year = "" }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["my-trade_snapshot", { cuid, year }],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/my/trade_snapshot?cuid=${cuid}&year=${year}`
      );

      return queryResult;
    },
    enabled: !!cuid,
    staleTime: 60 * 60 * 1000,
  });
}

function useDeleteMyNoteTradeSnapshot() {
  const httpClient = useHttpClient(api_v_url);

  return useMutation(
    async ({ cuid, year, month }) => {
      const { data: queryResult } = await httpClient.delete(
        `/v1b/my/trade_snapshot?cuid=${cuid}&year=${year}&month=${month}`
      );

      return queryResult?.data;
    },
    {
      mutationKey: ["my-trade_snapshot"],
      staleTime: 60 * 60 * 1000,
    }
  );
}

function useMyNoteSeedHistory() {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["my-seed_history"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/my/seed_history`
      );

      return queryResult?.data;
    },
    staleTime: 60 * 60 * 1000,
  });
}

function useUpdateMyNoteSeedHistory() {
  const httpClient = useHttpClient(api_v_url);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ before_date = "", after_date = "", seed = "" }) => {
      if (!after_date || !seed) {
        return;
      }
      const { data: queryResult } = await httpClient.post(
        `/v1b/my/seed_history?before_date=${before_date}&after_date=${after_date}&seed=${seed}`
      );
      return queryResult?.data;
    },
    {
      mutationKey: ["my-seed_history"],
      staleTime: 60 * 60 * 1000,
      onSuccess: () => {
        queryClient.invalidateQueries("my-seed_history");
      },
    }
  );
}

function useDeleteMyNoteSeedHistory({ date }) {
  const httpClient = useHttpClient(api_v_url);
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const { data: queryResult } = await httpClient.delete(
        `/v1b/my/seed_history?date=${date}`,
        {}
      );

      return queryResult?.data;
    },
    {
      mutationKey: ["my-seed_history"],
      staleTime: 60 * 60 * 1000,
      onSuccess: () => {
        queryClient.invalidateQueries("my-seed_history");
      },
    }
  );
}

function useMyNoteHistoryAttachment({ type }) {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["my-history_attachment"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/my/history_attachment?type=${type}`
      );

      return queryResult?.data;
    },
    staleTime: 60 * 60 * 1000,
  });
}

function useDeleteMyNoteHistory() {
  const httpClient = useHttpClient(api_v_url);
  return useMutation(
    async ({ temp_id }) => {
      const { data: queryResult } = await httpClient.delete(
        `/v1b/my/history?temp_id=${temp_id}`
      );

      return queryResult?.data;
    },
    {
      staleTime: 60 * 60 * 1000,
    }
  );
}

function useGetMyJournalRange() {
  const httpClient = useHttpClient(api_v_url);

  return useQuery({
    queryKey: ["my/journal_range"],
    queryFn: async () => {
      const { data: queryResult } = await httpClient.get(
        `/v1b/my/journal_range`
      );

      return queryResult?.data;
    },
    staleTime: 60 * 60 * 1000,
  });
}

function useUpdateMyReport() {
  const httpClient = useHttpClient(api_v_url);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ start, end }) => {
      const { data: queryResult } = await httpClient.post(
        `/v1b/my/report?start=${start}&end=${end}`
      );

      return queryResult?.data;
    },
    {
      onSuccess: (data) => {
        // Handle the response data here
        queryClient.invalidateQueries("my-trader");
        return data;
      },
      onError: (error) => {
        // Handle the error here
      },
      staleTime: 60 * 60 * 1000,
    }
  );
}

function useDeleteMyReport() {
  const httpClient = useHttpClient(api_v_url);
  const queryClient = useQueryClient();
  return useMutation(
    async ({ cuid }) => {
      const { data: queryResult } = await httpClient.delete(
        `/v1b/my/report?cuid=${cuid}`
      );

      return queryResult?.data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("my-trader");
      },
      staleTime: 60 * 60 * 1000,
    }
  );
}

export {
  useAlphaDetailHomeDailyGanttDateListQuery,
  useAlphaDetailHomeDailyGanttQuery,
  useAlphaDetailHomeNoteLossQuery,
  useAlphaDetailHomeNoteRecentQuery,
  useAlphaDetailHomeNoteRetQuery,
  useAlphaDetailHomeSummaryQuery,
  useAlphaDetailInfoChartQuery,
  useAlphaDetailInfoStyleQuery,
  useAlphaDetailInfoSummaryQuery,
  useAlphaDetailStockInfoQuery,
  useAlphaDetailStockListQuery,
  useAlphaDetailStockNoteQuery,
  useAlphaMyTraderQuery,
  useAlphaTraderDetailQuery,
  useAnalysisDateHistoryQuery,
  useAnalysisDatePerformanceQuery,
  useAnalysisDateStockChangeQuery,
  useAnalysisStockDailyQuery,
  useAnalysisStockHistoryQuery,
  useAnalysisStockPerformanceQuery,
  useAnalysisSummaryHoldingTimePerformanceQuery,
  useAnalysisSummaryPerformanceQuery,
  useDeleteMyNoteHistory,
  useDeleteMyNoteSeedHistory,
  useDeleteMyNoteTradeSnapshot,
  useDeleteMyReport,
  useGetMyJournalRange,
  useMyNoteAccountInfo,
  useMyNoteHistoryAttachment,
  useMyNoteSeedHistory,
  useMyNoteTradeSnapshot,
  // useTraderDateHistoryQuery,
  // useTraderDateProformanceQuery,
  useTraderDateStockQuery,
  useUpdateMyNoteAccointInfo,
  useUpdateMyNoteSeedHistory,
  useUpdateMyReport,
};
