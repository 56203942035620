import { useNote } from "hooks/queries/useNoteQuery";
import { createChart } from "lightweight-charts";
import { useLocation, useNavigate } from "react-router-dom";

import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import {
  addCommaToInt,
  convertToNearWon,
  convertToPercentage,
  convertToPnlRatio,
  convertToWon,
  formatDayTime,
  formatSec,
  unescapeHtml,
} from "utils/utils";

import { VertLine } from "utils/vertical-line";
import { WinningRateCard } from "./DetailSummarySection";

import { ReactComponent as ButtonDown } from "assets/images/btn_accordion_down.svg";
import { ReactComponent as ButtonUp } from "assets/images/btn_accordion_up.svg";
import NoticeModal from "components/Modals/NoticeModal";
import { SvgStkImg } from "components/StockIcon";
import { isMobile } from "react-device-detect";
import { useNotice } from "utils/store";

const Title = styled.div`
  color: #ffffff;
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.87px;
  text-align: left;
  margin-left: 10px;
`;

const ToggleWrapper = styled.div`
  width: 212px;
  margin-bottom: 16px;
`;

const HomeWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  min-width: 1128px;

  .section-bg {
    width: 100%;
    background: #121314;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .fullWidth {
    width: 1128px;
  }
`;

const TopSection = styled.div`
  background: #242424;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  max-width: 1088px;
  padding: 0 20px;
`;
const TitleTag = styled.div`
  margin-left: 8px;
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.32px;
  text-align: left;
  color: #7dcc61;
  border-radius: 16px;
  border: 1px solid #7dcc61;
  width: fit-content;
  padding: 4px 8px;

  .type {
    font-weight: 600;
    margin-right: 6px;
  }
`;
const ShareIcon = styled.div``;

const DataWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 28px;
  flex-direction: column;
  width: 100%;
  max-width: 1072px;
`;

const SectionTitle = styled.div`
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 600;
  line-height: 17.9px;
  text-align: left;

  margin-bottom: 9px;
`;

const DataSection = styled.div`
  padding-bottom: 48px;
`;

const SummaryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 12px;
  width: 100%;
  max-width: 1072px;
  text-align: left;
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;
`;

const TopWrapper = styled.div`
  width: 100%;
  background: #242424;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const SummaryCardWrapper = styled.div`
  padding: 20px;

  display: flex;
  flex-direction: column;

  border: ${(props) =>
    props.isGrid ? "1px dashed #ebedf5" : "1px solid #EBEBEB"};

  border-radius: 6px;

  .value {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
    text-align: right;
  }

  .infoWrapper {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
  }

  .info {
    color: #afb0b2;
    display: flex;
    justify-content: space-between;
    margin-top: 4px;

    font-family: Pretendard;
    font-size: 13px;
    font-weight: 400;
    line-height: 15.51px;
    text-align: left;

    opacity: 0.5;
  }
`;

const watermarkCommon = {
  color: "#000",
  visible: true,
  fontFamily: "Pretendard",
  horzAlign: "left",
  vertAlign: "top",
};

const markerColor = {
  B: "rgba(0, 0, 0, 1)",
  S: "rgba(0, 0, 0, 1)",
  // B: "rgba(255, 165, 0, 1)",
  // S: "rgba(0, 128, 0, 1)",
  // B: "rgba(76, 91, 255, 1)",
  // S: "rgba(255, 39, 39, 1)",
};

const maStyle = {
  // 5, 20, 60, 120, 240
  5: {
    color: "rgba(255,152,207,1)", // pink
    width: 0.8,
  },
  20: {
    color: "rgba(98,180,255,1)", // lightblue
    width: 0.9,
  },
  60: {
    color: "rgba(254,183,3,1)", // yellow
    width: 1.0,
  },
  120: {
    color: "rgba(0,150,33,1)", // green
    width: 1.1,
  },
  240: {
    color: "rgba(142,69,177,1)", // purple
    width: 1.2,
  },
};

const SummaryCard = ({ infoData = [] }) => {
  return (
    <SummaryCardWrapper>
      {infoData.map((item, index) => {
        return (
          <div className="infoWrapper">
            <div className="title">{item?.title}</div>
            <div className="value">{item?.value}</div>
          </div>
        );
      })}
    </SummaryCardWrapper>
  );
};

const SummaryArea = ({ summaryData }) => {
  return (
    <SummaryGrid>
      <WinningRateCard
        item={{
          title: "수익률",
          value: convertToPercentage(summaryData?.ret),
          info: {
            type: "text",
            value: [
              {
                text: "최저",
                value: convertToPercentage(summaryData?.min_ret),
              },
              {
                text: "최대",
                value: convertToPercentage(summaryData?.max_ret),
              },
            ],
          },
        }}
        isCard={true}
      />
      <WinningRateCard
        item={{
          title: "수익금",
          value: convertToNearWon(summaryData?.pnl),
          info: {
            type: "text",
            value: [
              { text: "최저", value: convertToNearWon(summaryData?.min_pnl) },
              { text: "최대", value: convertToNearWon(summaryData?.max_pnl) },
            ],
          },
        }}
        isCard={true}
      />
      <WinningRateCard
        item={{
          title: "거래대금",
          value: convertToNearWon(summaryData?.amt),
          info: {
            type: "text",
            value: [
              { text: "매수", value: convertToNearWon(summaryData?.amt_buy) },
              { text: "매도", value: convertToNearWon(summaryData?.amt_sell) },
            ],
          },
        }}
        isCard={true}
      />
      <WinningRateCard
        item={{
          title: "승률",
          value: convertToPercentage(summaryData?.win_rate),
          info: {
            win: summaryData?.n_win,
            lose: summaryData?.n_lose,
            type: "graph",
          },
        }}
        isCard={true}
      />

      <SummaryCard
        infoData={[
          {
            title: "손익비",
            value: convertToPnlRatio(summaryData?.pnl_ratio),
          },
          {
            title: "거래회전율",
            value: convertToPercentage(summaryData?.turnover),
          },
          {
            title: "평균보유시간",
            value: formatSec(summaryData?.avg_hold_time),
          },
          {
            title: "평균거래금액",
            value: convertToNearWon(summaryData?.avg_amt),
          },
        ]}
      />
    </SummaryGrid>
  );
};

// let chartInstances = {};
const chartWidth = 1128;
var chartHeights = {
  candle: 270,
  volume: 50,
  asset: 50,
};
const timeLableHeight = 25;
const toolTipWidth = 120;

const ChartContainer = styled.div`
  position: relative;
  width: 1072px;
  height: ${chartHeights.candle + 58} px;
`;

export const ChartArea = ({
  chartData,
  markersData,
  mode = "",
  name = "",
  maShow = true,
}) => {
  // const containerRef = useRef();
  const [currentType, setCurrentType] = useState("1min");
  const [chartSetup, setChartSetup] = useState(null);

  const containerRef = useRef();
  const [candleChart, setCandleChart] = useState(null);
  const [volumeChart, setVolumeChart] = useState(null);
  const [assetChart, setAssetChart] = useState(null);
  const [candleSeries, setCandleSeries] = useState(null);
  const [volumeSeries, setVolumeSeries] = useState(null);
  const [cMarkerSeriesList, setCMarkerSeriesList] = useState([]);
  const [vMarkerSeriesList, setVMarkerSeriesList] = useState([]);

  const cMarkerSeries = useRef([]);
  const vMarkerSeries = useRef([]);

  const cLineSeries = useRef();
  const vLineSeries = useRef();
  const aLineSeries = useRef();

  const [holdSeries, setHoldSeries] = useState(null);
  const [evalSeries, setEvalSeries] = useState(null);

  const toolTipHeight =
    mode === "extra"
      ? chartHeights.candle + chartHeights.volume + chartHeights.asset
      : chartHeights.candle + chartHeights.volume;
  // const createToolTip = (container) => {
  //   const toolTip = document.createElement('div');
  //   toolTip.style = `width: ${toolTipWidth}px; height: ${toolTipHeight}px; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border-radius: 4px 4px 0px 0px; border-bottom: none; box-shadow: 0 2px 5px 0 rgba(117, 134, 150, 0.45);font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
  //   toolTip.style.background = `rgba(${'255, 255, 255'}, 0.25)`;
  //   toolTip.style.color = 'black';
  //   toolTip.style.borderColor = 'rgba( 239, 83, 80, 1)';
  //   container.appendChild(toolTip);
  //   return toolTip;
  // }

  const candle = chartData?.candle;
  const volume = chartData?.volume;
  const markers = markersData;
  const n_hold = chartData?.n_hold;
  const evalAsset = chartData?.pnl;
  const n_agg = chartData?.n_agg;
  const code = chartData?.code;
  // const toolTip = createToolTip(container)
  // container.appendChild(toolTip);

  const ma = chartData?.ma;

  const CANDLE_OPTION = {
    width: chartWidth,
    layout: {
      textColor: "#AFB0B2",
      background: { type: "solid", color: "transparent" },
    },
    grid: {
      vertLines: { color: "#DCDEE3" },
      horzLines: { color: "#DCDEE3" },
    },
    timeScale: {
      visible: false,
    },
    localization: {
      priceFormatter: (price) => {
        return convertToWon(price);
      },
    },
    watermark: {
      ...watermarkCommon,
      fontSize: 14,
      text:
        n_agg < 60
          ? `alphanote.io ${name} ${n_agg}분봉`
          : n_agg < 1440
          ? `alphanote.io ${name} ${n_agg / 60}시간봉`
          : `alphanote.io ${name} ${n_agg / 1440}일봉`,
    },
    crosshair: {
      vertLine: {
        color: "#B7C3F3",
        width: 1,
        style: 1,
        visible: true,
        labelVisible: true,
      },
      horzLine: {
        color: "#B7C3F3",
        width: 1,
        style: 1,
        visible: true,
        labelVisible: true,
      },
      mode: 0,
    },
    rightPriceScale: {
      minimumWidth: 100,
    },
  };
  const VOLUME_OPTION = {
    width: chartWidth,
    layout: {
      textColor: "#AFB0B2",
    },
    grid: {
      vertLines: { color: "#DCDEE3" },
      horzLines: { color: "#DCDEE3" },
    },
    timeScale: {
      visible: mode === "extra" ? false : true,
    },
    localization: {
      priceFormatter: (amt) => {
        return amt === 0 ? `0원` : `${addCommaToInt(amt.toFixed(0))}억원`;
      },
    },
    watermark: {
      ...watermarkCommon,
      fontSize: 12,
      text: `거래대금`,
    },
    crosshair: {
      vertLine: {
        color: "#B7C3F3",
        width: 1,
        style: 1,
        visible: true,
        labelVisible: true,
      },
      horzLine: {
        color: "#B7C3F3",
        width: 1,
        style: 1,
        visible: true,
        labelVisible: true,
      },
      mode: 0,
    },
    layout: {
      background: {
        type: "solid",
        // color: "#F5F5FF",
      },
    },
    rightPriceScale: {
      minimumWidth: 100,
    },
  };
  const ASSET_OPTION = {
    width: chartWidth,
    layout: {
      textColor: "#AFB0B2",
    },
    grid: {
      vertLines: { color: "#DCDEE3" },
      horzLines: { color: "#DCDEE3" },
    },
    timeScale: {
      visible: mode === "extra" ? true : false,
    },
    localization: {
      priceFormatter: (price) => {
        return convertToNearWon(price);
      },
    },
    watermark: {
      ...watermarkCommon,
      fontSize: 12,
      // text: `평가자산 & 비중`,
      text: `비중`,
    },
    rightPriceScale: {
      minimumWidth: 100,
    },
  };

  const VerticalLineOption = {
    width: 1,
    color: "red",
  };

  const myChartResize = (candleInstance, volumeInstance, assetInstance) => {
    var largestPriceScaleWdith =
      mode === "extra"
        ? Math.min(
            candleInstance.priceScale("right").width(),
            volumeInstance.priceScale("right").width(),
            assetInstance.priceScale("right").width()
          )
        : Math.max(
            candleInstance.priceScale("right").width(),
            volumeInstance.priceScale("right").width()
          );

    var targetWidth =
      1128 -
      (largestPriceScaleWdith - candleInstance.priceScale("right").width());

    candleInstance.resize(targetWidth, chartHeights.candle);
    targetWidth =
      1128 -
      (largestPriceScaleWdith - volumeInstance.priceScale("right").width());

    volumeInstance.resize(
      targetWidth,
      mode === "extra"
        ? chartHeights.volume
        : chartHeights.volume + timeLableHeight
    );
    targetWidth =
      1128 -
      (largestPriceScaleWdith - assetInstance.priceScale("right").width());

    assetInstance.resize(
      targetWidth,
      mode === "extra" ? chartHeights.asset + timeLableHeight : 0
    );
  };

  // console.log(mode);
  const createToolTip = (container) => {
    const toolTip = document.createElement("div");
    toolTip.style = `width: ${toolTipWidth}px; height: ${toolTipHeight}px; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border-radius: 4px 4px 0px 0px; border-bottom: none; box-shadow: 0 2px 5px 0 rgba(117, 134, 150, 0.45);font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
    toolTip.style.background = `rgba(${"255, 255, 255"}, 0.25)`;
    toolTip.style.color = "black";
    toolTip.style.borderColor = "rgba( 239, 83, 80, 1)";
    container.appendChild(toolTip);
    return toolTip;
  };

  useEffect(() => {
    const candleInstance = createChart(containerRef.current, CANDLE_OPTION);
    candleInstance.priceScale("right").applyOptions({
      scaleMargins: {
        top: 0.05,
        bottom: 0.05,
      },
    });

    const volumeInstance = createChart(containerRef.current, VOLUME_OPTION);
    volumeInstance.priceScale("right").applyOptions({
      scaleMargins: {
        top: 0,
        bottom: 0,
      },
    });

    const assetInstance = createChart(containerRef.current, ASSET_OPTION);

    if (assetInstance) {
      assetInstance.timeScale().fitContent();
      const holdSeries = assetInstance.addLineSeries({
        color: "#B19CD9",
        lastValueVisible: false,
        crosshairMarkerVisible: true,
        lineWidth: 3,
        // follow left price scale
        priceScaleId: "left",
      });
      const eavlSeries = assetInstance.addLineSeries({
        color: "#4CAF50",
        lastValueVisible: false,
        crosshairMarkerVisible: true,
        lineWidth: 3,
        // follow right price scale
        priceScaleId: "right",
      });
    }

    const candleSeries = candleInstance.addCandlestickSeries();
    candleSeries.applyOptions({
      upColor: "#FF2727",
      wickUpColor: "#FF2727",
      downColor: "#4C67FF",
      wickDownColor: "#4C67FF",
      priceLineVisible: false,
      borderVisible: false,
      lastValueVisible: false,
    });

    const volumeSeries = volumeInstance.addHistogramSeries({
      color: "#26a69a",
      priceLineVisible: false,
      lastValueVisible: false,
      priceScaleId: "right",
    });

    const holdSeriesnew = assetInstance.addLineSeries({
      color: "rgba(33, 150, 243, .8)", // blue
      lastValueVisible: false,
      crosshairMarkerVisible: false,
      lineWidth: 2,
      lineStyle: 0,
      // follow left price scale
      priceScaleId: "left",
    });

    // const evalSeriesnew = assetInstance.addLineSeries({
    //   color: "rgba(40, 167, 69, 1)", // green
    //   lastValueVisible: false,
    //   crosshairMarkerVisible: true,
    //   lineWidth: 2,
    //   // follow right price scale
    //   priceScaleId: "right",
    // });

    // 차트 시간축 동기화
    candleInstance
      .timeScale()
      .subscribeVisibleLogicalRangeChange((timeRange) => {
        myChartResize(candleInstance, volumeInstance, assetInstance);

        if (timeRange) {
          volumeInstance.timeScale().setVisibleLogicalRange(timeRange);
          if (mode === "extra")
            assetInstance.timeScale().setVisibleLogicalRange(timeRange);
        }
      });
    volumeInstance
      .timeScale()
      .subscribeVisibleLogicalRangeChange((timeRange) => {
        myChartResize(candleInstance, volumeInstance, assetInstance);
        if (timeRange) {
          candleInstance.timeScale().setVisibleLogicalRange(timeRange);
          if (mode === "extra")
            assetInstance.timeScale().setVisibleLogicalRange(timeRange);
        }
      });

    if (mode === "extra") {
      assetInstance
        .timeScale()
        .subscribeVisibleLogicalRangeChange((timeRange) => {
          myChartResize(candleInstance, volumeInstance, assetInstance);
          if (timeRange) {
            candleInstance.timeScale().setVisibleLogicalRange(timeRange);
            volumeInstance.timeScale().setVisibleLogicalRange(timeRange);
          }
        });
    }

    candleInstance.timeScale().applyOptions({
      timeVisible: true,
      secondsVisible: true,
    });
    volumeInstance.timeScale().applyOptions({
      timeVisible: true,
      secondsVisible: true,
    });
    assetInstance.timeScale().applyOptions({
      timeVisible: true,
      secondsVisible: true,
    });

    setCandleChart(candleInstance);
    setVolumeChart(volumeInstance);
    setAssetChart(assetInstance);

    setCandleSeries(candleSeries);
    setVolumeSeries(volumeSeries);

    // setCMarkerSeriesList(cMarkerSeriesListnew);
    // setVMarkerSeriesList(vMarkerSeriesListnew);

    setHoldSeries(holdSeriesnew);
    // setEvalSeries(evalSeriesnew);

    const toolTip = createToolTip(containerRef.current);
    containerRef.current.appendChild(toolTip);

    candleInstance.subscribeCrosshairMove(function (param) {
      if (!cMarkerSeries) return;
      if (!param.time || param.seriesData.size === 0) {
        toolTip.style.display = "none";
        return;
      }

      // console.log("param", param);
      let candleData = {};
      let maindex = 0;
      const maindexkey = ["ma5", "ma20", "ma60", "ma120", "ma240"];

      param?.seriesData?.forEach((data) => {
        // Access each key-value pair in the seriesData object

        Object.entries(data).forEach(([key, value]) => {
          // console.log("!Key", key, value);
          if (key === "time") {
            chartData?.markers?.forEach((marker) => {
              if (marker.time === value) candleData["marker"] = true;
            });
          }
          if (key === "value") {
            candleData[maindexkey[maindex++]] = value;
          } else {
            candleData[key] = value;
          }
        });
      });
      // param?.seriesData?.forEach((key, value) => {
      //   console.log("value", value);
      // });
      // console.log("candle", param.point);

      if (candleData) {
        toolTip.innerHTML =
          (candleData?.customValues?.marker
            ? `<div>${new Date(candleData.time * 1000)
                .toISOString()
                .slice(5, 16)
                .replace("T", " ")} | ${candleData?.customValues?.value}원 |  ${
                candleData?.customValues?.amt + "주 "
              }${
                candleData?.customValues?.marker === "sell" ? "매도" : "매수"
              }</div>`
            : ``) +
          // show utc time with formmat 'MM-dd HH:mm:ss'
          (candleData?.time
            ? `<div>${new Date(candleData.time * 1000)
                .toISOString()
                .slice(5, 16)
                .replace("T", " ")}</div>`
            : ``) +
          (candleData?.open
            ? `<div>시가: ${candleData?.open.toFixed(0)}</div>`
            : ``) +
          (candleData?.high
            ? `<div>고가: ${candleData?.high.toFixed(0)}</div>`
            : ``) +
          (candleData?.low
            ? `<div>저가: ${candleData?.low.toFixed(0)}</div>`
            : ``) +
          (candleData?.close
            ? `<div>종가: ${candleData?.close.toFixed(0)}</div>`
            : ``) +
          (candleData?.customValues?.volume
            ? `<div>거래대금: ${candleData?.customValues?.volume.toFixed(
                0
              )}</div>`
            : ``) +
          `<div></div>` +
          `<div>이평선</div>` +
          (candleData?.ma5
            ? `<div>5: ${candleData.ma5.toFixed(0)}</div>`
            : ``) +
          (candleData?.ma20
            ? `<div>20: ${candleData.ma20.toFixed(0)}</div>`
            : ``) +
          (candleData?.ma60
            ? `<div>60: ${candleData.ma60.toFixed(0)}</div>`
            : ``) +
          (candleData?.ma120
            ? `<div>120: ${candleData.ma120.toFixed(0)}</div>`
            : ``) +
          (candleData?.ma240
            ? `<div>240: ${candleData.ma240.toFixed(0)}</div>`
            : ``);

        // `<div>대금: ${volumeData ? volumeData.value.toFixed(0) : 'N/A'}</div>`;

        let left = param.point.x - toolTipWidth / 2; // relative to timeScale

        toolTip.style.left = left + "px";
        toolTip.style.top = 0 + "px";
        toolTip.style.display = "block";
      } else {
        toolTip.style.display = "none";
      }

      if (mode === "extra") {
      }
    });

    volumeInstance.subscribeCrosshairMove(function (param) {
      // console.log("param", param);
      // if (!cMarkerSeries) return;
      if (!param.time || param.seriesData.size === 0) {
        toolTip.style.display = "none";
        return;
      }

      let candleData = {};

      param?.seriesData?.forEach((data) => {
        if (data.hasOwnProperty("color")) {
          candleData = data;
        }
      });
      // param?.seriesData?.forEach((key, value) => {
      //   console.log("value", value);
      // });

      if (candleData) {
        toolTip.innerHTML =
          // show utc time with formmat 'MM-dd HH:mm:ss'
          `<div>${new Date(candleData.time * 1000)
            .toISOString()
            .slice(5, 16)
            .replace("T", " ")}</div>` +
          `<div></div>` +
          `<div>거래대금</div>` +
          `<div>거래대금: ${candleData.value}</div>`;

        // `<div>대금: ${volumeData ? volumeData.value.toFixed(0) : 'N/A'}</div>`;

        let left = param.point.x - toolTipWidth / 2; // relative to timeScale

        toolTip.style.left = left + "px";
        toolTip.style.top = 0 + "px";
        toolTip.style.display = "block";
      } else {
        toolTip.style.display = "none";
      }

      if (mode === "extra") {
      }
    });

    return () => {
      candleInstance.remove();
      volumeInstance.remove();
      assetInstance.remove();
    };
  }, []);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }

    if (chartData && containerRef.current && candleSeries && volumeSeries) {
      // 차트 좌우 길이 맞추기, 오른쪽 가격 표시 부분 넓이가 변할 때 차트 넓이를 조절
      if (chartData?.candle && chartData.volume) {
        candleChart.removeSeries(candleSeries);
        const ncandleSeries = candleChart.addCandlestickSeries();
        ncandleSeries.applyOptions({
          upColor: "#FF2727",
          wickUpColor: "#FF2727",
          downColor: "#4C67FF",
          wickDownColor: "#4C67FF",
          priceLineVisible: false,
          borderVisible: false,
          lastValueVisible: false,
        });

        ncandleSeries.setData(
          chartData?.candle.map((item) => ({
            ...item,
            customValues: { volume: item.volume },
          })) || []
        );
        setCandleSeries(ncandleSeries);

        // add moving average series
        // chartData.ma is dictionary with key as ma period and value as array of ma values
        if (maShow && chartData?.ma) {
          Object.keys(chartData.ma).forEach((key) => {
            const maSeries = candleChart.addLineSeries({
              color: maStyle[key].color,
              lineWidth: maStyle[key].width,
              priceLineVisible: false,
              lastValueVisible: false,
              priceScaleId: "right",
            });
            maSeries.setData(chartData.ma[key]);
          });
        }

        if (cLineSeries?.current) {
          candleChart.removeSeries(cLineSeries.current);
        }
        cLineSeries.current = candleChart.addLineSeries();

        chartData?.vert_lines?.forEach((line) => {
          const vertLine = new VertLine(
            candleChart,
            cLineSeries.current,
            line.timestamp, //여기에 하나하나 넣어주어야 함
            {
              ...VerticalLineOption,
              showLabel: false,
              labelText: "",
            }
          );
          cLineSeries.current.attachPrimitive(vertLine);
        });

        if (vLineSeries?.current) {
          volumeChart.removeSeries(vLineSeries.current);
        }

        vLineSeries.current = volumeChart.addLineSeries();

        chartData?.vert_lines?.forEach((line) => {
          const vertVolumeLine = new VertLine(
            volumeChart,
            vLineSeries.current,
            line.timestamp, //여기에 하나하나 넣어주어야 함
            {
              ...VerticalLineOption,
              showLabel: false,
              labelText: "",
            }
          );
          vLineSeries.current.attachPrimitive(vertVolumeLine);
        });

        aLineSeries.current = assetChart.addLineSeries();

        chartData?.vert_lines?.forEach((line) => {
          const vertAssetLine = new VertLine(
            assetChart,
            aLineSeries.current,
            line.timestamp, //여기에 하나하나 넣어주어야 함
            {
              ...VerticalLineOption,
              showLabel: false,
              labelText: "",
            }
          );
          aLineSeries.current.attachPrimitive(vertAssetLine);
        });

        volumeChart.removeSeries(volumeSeries);
        const nvolumeSeries = volumeChart.addHistogramSeries({
          color: "#26a69a",
          priceLineVisible: false,
          lastValueVisible: false,
          priceScaleId: "right",
        });

        nvolumeSeries.setData(chartData?.volume || []);
        setVolumeSeries(nvolumeSeries);
        if (chartData?.n_hold) holdSeries.setData(chartData?.n_hold || []);
        // if (chartData?.pnl) evalSeries.setData(chartData?.pnl || []);
      }

      cMarkerSeries.current?.forEach((series) => {
        try {
          candleChart.removeSeries(series);
        } catch (e) {
          console.log(e);
        }
      });

      cMarkerSeries.current =
        markersData?.map((marker, idx) => {
          const series = candleChart.addLineSeries({
            color: "rgba(0, 0, 0, 0)",
            lastValueVisible: false,
            crosshairMarkerVisible: false,
            priceLineVisible: false,
          });

          series.setData([
            {
              time: marker?.time,
              value: marker?.value,
              customValues: {
                marker: marker?.shape === "arrowUp" ? "buy" : "sell",
                amt: marker?.amt,
                value: marker?.value,
              },
            },
          ]);
          series.setMarkers([
            {
              time: marker.time,
              position: "inBar", //marker.position,
              // shape: marker.shape,
              shape: marker.shape === "arrowUp" ? "buy" : "sell",
              color: markerColor[marker.text],
              // text: marker.text,
              size: 1, // marker.size,
            },
          ]);

          return series;
        }) || null;

      vMarkerSeries.current?.forEach((series) => {
        try {
          volumeChart.removeSeries(series);
        } catch (e) {
          console.log(e);
        }
      });

      vMarkerSeries.current =
        markersData?.map((marker, idx) => {
          const series = volumeChart.addLineSeries({
            color: "rgba(0, 0, 0, 0)",
            lastValueVisible: false,
            crosshairMarkerVisible: false,
            priceLineVisible: false,
            priceScaleId: "left",
          });

          series.setData([{ time: marker?.time, value: 0 }]);
          series.setMarkers([
            {
              time: marker.time,
              position: marker.position,
              // shape: marker.shape,
              color: "rgba(0, 0, 0, 0)",
              // text: marker.text,
              size: marker.size,
            },
          ]);

          return series;
        }) || [];

      setCMarkerSeriesList(cMarkerSeries.current);
      setVMarkerSeriesList(vMarkerSeries.current);

      // 차트가 마진이 있다가 없다가 하는 이슈가 있음 TBD
      candleChart.timeScale().fitContent();
      volumeChart.timeScale().fitContent();

      const range = candleChart.timeScale().getVisibleLogicalRange();
      const range_from = range.from;
      const range_to = range.to;
      const padding_right = n_agg >= 1440 ? 2 : Math.max(5, range.to / 50); // 일봉은 2개, 그 외는 max(5, 전체 2%)

      candleChart.timeScale().setVisibleLogicalRange({
        from: 0,
        to: range_to + padding_right,
      });

      // change watermark text
      candleChart.applyOptions({
        watermark: {
          ...watermarkCommon,
          fontSize: 14,
          text:
            n_agg < 60
              ? `alphanote.io ${name} ${n_agg}분봉`
              : n_agg < 1440
              ? `alphanote.io ${name} ${n_agg / 60}시간봉`
              : // : `alphanote.io ${name} ${n_agg / 1440}일봉`,
                `alphanote.io ${name} 일봉`,
        },
      });
    }
  }, [
    chartData,
    markersData,
    candleChart,
    volumeChart,
    assetChart,
    cMarkerSeries,
    vMarkerSeries,
    // candleSeries,
    // volumeSeries,
    // cMarkerSeriesList,
    // vMarkerSeriesList,
    mode,
  ]);

  return (
    <>
      {/* <ToggleWrapper>
        <ToggleComponet
          onClick={(type) => setCurrentType(type)}
          labels={[
            { name: "1분", type: "1min" },
            { name: "3분", type: "3min" },
            { name: "5분", type: "5min" },
            { name: "30분", type: "30min" },
          ]}
        />
      </ToggleWrapper> */}

      <ChartBackgroundWrapper>
        <ChartContainer ref={containerRef} />
        {maShow && chartData?.ma && (
          <MALabelWrapper>
            {Object.keys(chartData.ma).map((key) => {
              return <MALabel color={maStyle[key].color}>{key + "  "}</MALabel>;
            })}
          </MALabelWrapper>
        )}
      </ChartBackgroundWrapper>
    </>
  );
};

const ChartBackgroundWrapper = styled.div`
  display: flex;
  position: relative;
`;

const MALabelWrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 12px;
`;

const MALabel = styled.span`
  color: ${(props) => props.color};
`;

const HistoryWrapper = styled.div`
  border-top: 1px solid #323233;
`;
const ReportInfoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 0;
  width: 100%;
  border-bottom: 1px solid #E7E9EF;
}`;

const ReportInfoCellWrapper = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;
  color: #000000;

  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 16px 12px;

  .title {
    font-size: 15px;
    line-height: 17.9px;
  }

  .code {
    color: #646566;
  }

  .tag {
    font-size: 12px;
    font-weight: 700;
    line-height: 14.32px;
    text-align: center;
    background: ${(props) => (props.win ? "#FF5857" : "#4C67FF")};
    width: fit-content;
    padding: 3px 6px;
    color: #fff;
    border-radius: 4px;
  }
`;

const ReportInfoCell = ({ title = "뷰노", code = "12312", win = true }) => {
  return (
    <ReportInfoCellWrapper win={win}>
      <div className="title">{title}</div>
      <div className="code">{code}</div>
      <div className="tag">{win ? "승리" : "패배"}</div>
    </ReportInfoCellWrapper>
  );
};

const ReportCellWrapper = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;
  text-align: left;

  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px 12px;

  .title {
    color: #969799;
  }
`;

const CellValue = styled.div`
  margin-top: 5px;
  color: ${(props) => props.$valuecolor};
`;

const ReportCell = ({
  title1 = "",
  title2 = "",
  value1 = "",
  value2 = "",
  value1Color = "#191A1A",
  value2Color = "#191A1A",
}) => {
  return (
    <ReportCellWrapper>
      <div className="infoWrapper">
        <div className="title">{title1}</div>
        <CellValue $valuecolor={value1Color}>{value1}</CellValue>
      </div>
      <div className="infoWrapper">
        <div className="title">{title2}</div>
        <CellValue $valuecolor={value2Color}>{value2}</CellValue>
      </div>
    </ReportCellWrapper>
  );
};

const ReportButtonCellWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    &:hover {
      cursor: pointer;
    }
  }
`;

const ReportButtonCell = ({ onClick, isOpen = false }) => {
  return (
    <ReportButtonCellWrapper>
      {isOpen ? (
        <ButtonUp className="button" onClick={onClick} />
      ) : (
        <ButtonDown className="button" onClick={onClick} />
      )}
    </ReportButtonCellWrapper>
  );
};
const AccordionWrapper = styled.div`
  padding: 20px;
  background: #f7f8fa;
`;

const AccodionTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  padding: 20px;

  font-family: Pretendard;
  font-size: 13px;

  line-height: 15.51px;
  text-align: left;
`;

const TableHeader = styled.th`
  padding: 8px;
  background: #edeff2;
  font-weight: 500;
  text-align: right;
`;

const TableData = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ebedf5;

  font-weight: 400;
  line-height: 15.51px;
  text-align: right;
`;

const InfoSection = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;
  text-align: left;
  color: #969799;
  margin-bottom: 20px;

  span {
    font-weight: 700;
  }
`;

const POSITIVE_COLOR = "#FF2727";
const NEGATIVE_COLOR = "#4C67FF";

const ReportCard = ({ report: trs, stockData }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <ReportInfoGrid>
        <ReportInfoCell
          title={unescapeHtml(trs?.name)}
          code={trs?.code}
          win={trs?.ret > 0}
        />
        <ReportCell
          title1={"진입"}
          title2={"청산"}
          value1={formatDayTime(trs?.start)}
          value2={formatDayTime(trs?.end)}
        />
        <ReportCell
          title1={"수익률"}
          title2={"수익금"}
          value1={convertToPercentage(trs?.ret)}
          value2={convertToNearWon(trs?.pnl)}
          value1Color={trs?.ret > 0 ? POSITIVE_COLOR : NEGATIVE_COLOR}
        />
        <ReportCell
          title1={"최대보유수량"}
          title2={"최대매수금액"}
          value1={addCommaToInt(trs?.max_n_hold) + "주"}
          value2={convertToNearWon(trs?.max_amt_buy)}
        />
        <ReportCell
          title1={"매수"}
          title2={"매도"}
          value1={convertToNearWon(trs?.amt_buy)}
          value2={convertToNearWon(trs?.amt_sell)}
        />
        <ReportCell
          title1={"체결"}
          title2={"보유시간"}
          value1={trs?.n_trx + "회"}
          value2={formatSec(trs?.hold_time)}
        />
        <ReportButtonCell
          isOpen={isOpen}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
      </ReportInfoGrid>
      {isOpen && (
        <AccordionWrapper>
          <AccodionTable>
            <thead>
              <tr>
                <TableHeader key={"date"}>{"체결시간"}</TableHeader>
                <TableHeader key={"order"}>{"주문"}</TableHeader>
                <TableHeader key={"prc"}>{"체결가"}</TableHeader>
                <TableHeader key={"vol"}>{"수량"}</TableHeader>
                <TableHeader key={"n_hold"}>{"보유수량"}</TableHeader>
                <TableHeader key={"amt"}>{"체결액"}</TableHeader>
                <TableHeader key={"market_val"}>{"평가금액"}</TableHeader>
                <TableHeader key={"pnl"}>{"수익금"}</TableHeader>
                <TableHeader key={"ret"}>{"수익률"}</TableHeader>
                <TableHeader key={"hold_time"}>{"보유기간"}</TableHeader>
              </tr>
            </thead>
            <tbody>
              {trs?.trxs.map((row, index) => (
                <tr key={index}>
                  <TableData key={"time"}>{formatDayTime(row.time)}</TableData>
                  <TableData key={"order"}>
                    {row.order === "buy"
                      ? "매수"
                      : row.order === "sell"
                      ? "매도"
                      : row.order}
                  </TableData>
                  <TableData key={"prc"}>{convertToWon(row?.prc)}</TableData>
                  <TableData key={"vol"}>{addCommaToInt(row?.vol)}</TableData>
                  <TableData key={"n_hold"}>
                    {addCommaToInt(row?.n_hold)}
                  </TableData>
                  <TableData key={"amt"}>
                    {convertToNearWon(row?.amt)}
                  </TableData>
                  <TableData key={"market_val"}>
                    {convertToNearWon(row?.market_val)}
                  </TableData>
                  <TableData key={"pnl"}>
                    {row.pnl ? convertToNearWon(row.pnl) : "-"}
                  </TableData>
                  <TableData key={"ret"}>
                    {row.ret ? convertToPercentage(row.ret) : "-"}
                  </TableData>
                  <TableData key={"holding_time"}>
                    {formatSec(row.hold_time)}
                  </TableData>
                </tr>
              ))}
            </tbody>
          </AccodionTable>
        </AccordionWrapper>
      )}
    </div>
  );
};

const HistoryArea = ({ reportsData: trs, stockData }) => {
  // Add your trade area code here

  return (
    <HistoryWrapper>
      {trs?.map((report, index) => {
        return <ReportCard key={index} report={report} stockData={stockData} />;
      })}
    </HistoryWrapper>
  );
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const StockDetail = () => {
  const navigate = useNavigate();

  const query = useQuery();
  const cuid = query.get("cuid");
  const date = query.get("date");
  const code = query.get("code");

  if (!cuid || !date || !code) {
    navigate("/");
  }

  const note_id = {
    cuid: cuid,
    date: date,
    code: code,
  };

  const { data, isError, isLoading } = useNote({ note_id });

  const { isNoticeShow } = useNotice((state) => state);
  const [isNoticeOpen, setNoticeOpen] = useState(false);

  useEffect(() => {
    if (isMobile && isNoticeShow === false) {
      setNoticeOpen(true);
    }
  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (isError) {
    return <p>Error loading data</p>;
  }

  const { info, chart, markers, trs, summary, day_chart, day_markers } =
    data || {};

  document.title = `${unescapeHtml(info.nick)} - ${unescapeHtml(
    info?.stock_name
  )}`;

  return (
    <HomeWrapper>
      <NoticeModal
        isOpen={isNoticeOpen}
        onCancel={() => {
          setNoticeOpen(false);
        }}
      />
      <TopWrapper>
        <TopSection>
          <SvgStkImg
            code={info?.stock_code}
            name={info?.stock_name}
            size={20}
          />
          <Title>{unescapeHtml(info?.stock_name)}</Title>
          <TitleTag>
            {/* 인터데이 인트라데이 표기 제한 */}
            {/* <span className="type">{info?.type}</span> */}
            <span>{info?.daterange}</span>
          </TitleTag>
          <ShareIcon />
        </TopSection>
      </TopWrapper>
      <DataWrapper>
        <InfoSection>
          <span>{info?.nick}</span>님의 {info?.cuid} '
          {unescapeHtml(info?.stock_name)}
          '종목의 {info?.daterange} 거래내역입니다.
        </InfoSection>
        <DataSection>
          <SectionTitle>요약</SectionTitle>
          <SummaryArea summaryData={summary} />
        </DataSection>
        <DataSection>
          <SectionTitle>일봉 차트</SectionTitle>
          <ChartArea
            chartData={day_chart}
            markersData={day_markers}
            name={unescapeHtml(info?.stock_name) || ""}
          />
        </DataSection>
        <DataSection>
          <SectionTitle>분봉 차트</SectionTitle>
          <ChartArea
            chartData={chart}
            markersData={markers}
            mode={"extra"}
            name={unescapeHtml(info?.stock_name) || ""}
          />
        </DataSection>
        <DataSection>
          <SectionTitle>거래</SectionTitle>
          <HistoryArea reportsData={trs} />
        </DataSection>
      </DataWrapper>
    </HomeWrapper>
  );
};

export default StockDetail;
