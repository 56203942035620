import { useState } from "react";
import styled from "styled-components";
import {
  addCommaToInt,
  convertToNearWon,
  convertToPercentage,
  convertToWon,
  formatDayTime,
  formatSec,
} from "utils/utils";

import { ReactComponent as ButtonDown } from "assets/images/btn_accordion_down.svg";
import { ReactComponent as ButtonUp } from "assets/images/btn_accordion_up.svg";

const HistoryWrapper = styled.div`
  border-top: 1px solid #323233;
`;
const ReportInfoGrid = styled.div`
  display: grid;
  grid-template-columns: 132px 170px 170px 140px 140px 140px 120px 60px;
  grid-template-rows: 80px;
  gap: 0;
  width: 100%;
  border-bottom: 1px solid #E7E9EF;
}`;

const ReportInfoCellWrapper = styled.div`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.71px;
  text-align: left;
  color: #646566;

  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
  padding: 0 12px;

  .title {
    font-size: 15px;
    line-height: 17.9px;
  }

  .code {
    color: #646566;
  }

  .tag {
    font-size: 12px;
    font-weight: 700;
    line-height: 14.32px;
    text-align: center;
    background: ${(props) => (props.$win ? "#FF2727" : "#4C67FF")};
    width: fit-content;
    padding: 3px 6px;
    color: #fff;
    border-radius: 4px;
  }
`;

const ReportInfoCell = ({
  title = "뷰노",
  code = "12312",
  win = true,
  clear = true,
}) => {
  return (
    <ReportInfoCellWrapper $win={win}>
      <div className="title">{title}</div>
      <div className="tag">{clear ? (win ? "익절" : "손절") : "보유"}</div>
    </ReportInfoCellWrapper>
  );
};

const ReportCellWrapper = styled.div`
  box-sizing: border-box;
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;
  text-align: left;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 12px;
  padding: 16px 12px;

  .title {
    color: #969799;
  }
`;

const CellValue = styled.div`
  margin-top: 5px;
  color: ${(props) => props.$valuecolor};
`;

const ReportCell = ({ title1 = "", value1 = "", value1Color = "#191A1A" }) => {
  return (
    <ReportCellWrapper>
      <div className="infoWrapper">
        <div className="title">{title1}</div>
        <CellValue $valuecolor={value1Color}>{value1}</CellValue>
      </div>
    </ReportCellWrapper>
  );
};

const ReportButtonCellWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .button {
    &:hover {
      cursor: pointer;
    }
  }
`;

const ReportButtonCell = ({ onClick, isOpen = false }) => {
  return (
    <ReportButtonCellWrapper>
      {isOpen ? (
        <ButtonUp className="button" onClick={onClick} />
      ) : (
        <ButtonDown className="button" onClick={onClick} />
      )}
    </ReportButtonCellWrapper>
  );
};
const AccordionWrapper = styled.div`
  padding: 20px;
  background: #f7f8fa;
`;

const AccodionTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  padding: 20px;

  font-family: Pretendard;
  font-size: 13px;

  line-height: 15.51px;
  text-align: left;
  th {
    box-sizing: border-box;
  }

  .sell {
    background: #f0f2f5;
  }
  .buy {
    background: #f9fafc;
  }

  th:nth-child(1) {
    width: 212px;
    border-radius: 4px 0 0 0;
  }

  th:nth-child(2) {
    width: 100px;
  }

  th:nth-child(3),
  th:nth-child(4),
  th:nth-child(7) {
    width: 120px;
  }
  th:nth-child(5),
  th:nth-child(6) {
    width: 180px;
  }

  th:nth-child(7) {
    border-radius: 0 4px 0 0;
  }

  .date {
    text-align: left;
  }
`;

const TableHeader = styled.th`
  padding: 12px;
  background: #e8eaed;
  color: #000000;
  font-weight: 500;
  text-align: right;

  radius: 12px;
`;

const TableData = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ebedf5;
  color: #323233;

  font-weight: 400;
  line-height: 15.51px;
  text-align: right;

  color: ${(props) => (props?.color ? props.color : "#323233")};
`;

const InfoSection = styled.div`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.51px;
  text-align: left;
  color: #969799;
  margin-bottom: 20px;

  span {
    font-weight: 700;
  }
`;

const POSITIVE_COLOR = "#FF2727";
const NEGATIVE_COLOR = "#4C67FF";

const ReportCard = ({ report: trs, stockData, idx }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <ReportInfoGrid>
        <ReportInfoCell
          title={`#${idx + 1}`}
          code={trs?.code}
          clear={trs?.clear}
          win={trs?.ret > 0}
        />
        <ReportCell title1={"진입"} value1={formatDayTime(trs?.start)} />
        <ReportCell title1={"청산"} value1={formatDayTime(trs?.end)} />
        <ReportCell title1={"보유시간"} value1={formatSec(trs?.hold_time)} />
        <ReportCell title1={"투자금"} value1={convertToNearWon(trs?.amt_buy)} />
        <ReportCell title1={"수익금"} value1={convertToNearWon(trs?.pnl)} />
        <ReportCell
          title1={"수익률"}
          value1={convertToPercentage(trs?.ret)}
          value1Color={trs?.ret > 0 ? POSITIVE_COLOR : NEGATIVE_COLOR}
        />

        <ReportButtonCell
          isOpen={isOpen}
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        />
      </ReportInfoGrid>
      {isOpen && (
        <AccordionWrapper>
          <AccodionTable>
            <thead>
              <tr>
                <TableHeader className="date" key={"date"}>
                  {"체결시간"}
                </TableHeader>
                <TableHeader key={"order"}>{"주문"}</TableHeader>
                <TableHeader key={"prc"}>{"체결가"}</TableHeader>
                <TableHeader key={"vol"}>{"수량"}</TableHeader>
                <TableHeader key={"market_val"}>{"평가금액"}</TableHeader>
                <TableHeader key={"pnl"}>{"수익금"}</TableHeader>
                <TableHeader key={"ret"}>{"수익률"}</TableHeader>
              </tr>
            </thead>

            <tbody>
              {trs?.trxs.map((row, index) => (
                <tr key={index} className={row.order}>
                  <TableData key={"time"} className="date">
                    {formatDayTime(row.time)}
                  </TableData>
                  <TableData key={"order"}>
                    {row.order === "buy"
                      ? "매수"
                      : row.order === "sell"
                      ? "매도"
                      : row.order === "hold"
                      ? "보유"
                      : row.order}
                  </TableData>
                  <TableData key={"prc"}>{convertToWon(row?.prc)}</TableData>
                  <TableData key={"vol"}>{addCommaToInt(row?.vol)}</TableData>
                  <TableData key={"market_val"}>
                    {convertToNearWon(row?.market_val)}
                  </TableData>
                  <TableData key={"pnl"}>
                    {row.pnl ? convertToNearWon(row.pnl) : "-"}
                  </TableData>
                  <TableData
                    key={"ret"}
                    color={row.ret > 0 ? POSITIVE_COLOR : NEGATIVE_COLOR}
                  >
                    {row.ret ? convertToPercentage(row.ret) : "-"}
                  </TableData>
                </tr>
              ))}
            </tbody>
          </AccodionTable>
        </AccordionWrapper>
      )}
    </div>
  );
};

export const HistoryArea = ({ reportsData: trs, stockData }) => {
  return (
    <HistoryWrapper>
      {trs?.map((report, index) => {
        return (
          <ReportCard
            key={index}
            idx={index}
            report={report}
            stockData={stockData}
          />
        );
      })}
    </HistoryWrapper>
  );
};
