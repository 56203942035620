import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const FooterWrapper = styled.footer`
  background: #f3f4f7;
  color: #fff;
  padding: 28px 16px 40px; // 좌우 패딩 추가
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
`;

const Title = styled.div`
  margin-bottom: 17px;
  color: #000;
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
  text-align: left;

  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;

const BodyText = styled.p`
  margin: 0 0 8px;
  color: #000;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  opacity: 0.4;
  text-align: left;
  width: 100%;

  @media (max-width: 768px) {
    font-size: 13px;
  }
`;

const ContainerWrapper = styled.div`
  width: 100%;
  max-width: 1128px;

  @media (max-width: 1200px) {
    max-width: 90%;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const BodyWrapper = styled.div`
  width: 100%;
  margin-top: 32px;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 36px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 16px;
  }
`;

const LinkItem = styled(Link)`
  color: #000;
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.9px;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    margin-left: 0;
    font-size: 14px;
  }
`;

const Footer = () => {
  const location = useLocation();
  const hideTopNavigation = location.pathname.startsWith("/notee");

  if (hideTopNavigation) {
    return null;
  }

  return (
    <FooterWrapper>
      <ContainerWrapper>
        <TopWrapper>
          <Title>AlphaNote</Title>
          <LinkWrapper>
            <LinkItem to="https://www.arpa.co.kr/4f30be1936434fb59c4d3c80f779841f">
              고객센터
            </LinkItem>
            <LinkItem to="https://arpa.co.kr">기업소개</LinkItem>
            <LinkItem to="https://tally.so/r/w5dGrN">의견 보내기</LinkItem>
            <LinkItem to="https://tally.so/r/3XeMQz">비지니스 문의</LinkItem>
            <LinkItem
              to="https://www.arpa.co.kr/124ace638ed680c6956fecfbee1add64"
              target="_blank"
            >
              개인정보처리방침
            </LinkItem>
            <LinkItem
              to="https://www.arpa.co.kr/124ace638ed6815db3e0c676a64f7302"
              target="_blank"
            >
              이용약관
            </LinkItem>
          </LinkWrapper>
        </TopWrapper>
        <BodyWrapper>
          <BodyText>(주)알파알피에이</BodyText>
          <BodyText>대표자명 : 이일규</BodyText>
          <BodyText>사업자등록번호: 559-87-02627</BodyText>
          <BodyText>통신판매업신고번호: 제2024-서울강남-06190 호</BodyText>
          <BodyText>
            주소 : 서울시 강남구 역삼로 169 팁스타운S2 청년창업스페이스
          </BodyText>
          <BodyText>연락처 : 010-2605-2193</BodyText>
          <BodyText>카카오톡 채널 : http://pf.kakao.com/_VtPxcG</BodyText>
          <BodyText>© 2024 Alpha RPA Inc. All rights reserved.</BodyText>
        </BodyWrapper>
      </ContainerWrapper>
    </FooterWrapper>
  );
};

export default Footer;
