import { useQuery, useMutation, useQueryClient } from "react-query";
import useHttpClient, { api_v_url } from "hooks/useHttpClient";

export const QUERY_KEYS = {
  USER: "user-info",
  USER_PROFILE: "user-profile",
};

export function useGetUserInfo() {
  const httpClient = useHttpClient(api_v_url);

  return useQuery(
    [QUERY_KEYS.USER],
    async () => {
      const { data } = await httpClient.get("/v1b/user/me");
      return data.data;
    },
    {
      staleTime: 60 * 1000,
      retry: false,
    }
  );
}

export function useCheckNickname() {
  const httpClient = useHttpClient(api_v_url);

  return useMutation(async (nickname) => {
    const { data } = await httpClient.get("/v1b/user/is_nickname_available", {
      params: { nickname },
    });
    return data;
  });
}

export function useUpdateProfile() {
  const httpClient = useHttpClient(api_v_url);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ user }) => {
      const formData = new FormData();

      console.log("user", user);

      // 각 필드를 FormData에 추가
      if (user.nickname) formData.append("nickname", user.nickname);
      if (user.email) formData.append("email", user.email);
      if (user.removePicture) formData.append("remove_picture", true);
      if (user.picture) formData.append("picture", user.picture);

      console.log("formData", formData);

      const { data } = await httpClient.post("/v1b/user/me/update", formData);
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.USER);
      },
    }
  );
}

export function useRequestEmailVerification() {
  const httpClient = useHttpClient(api_v_url);

  return useMutation(async (email) => {
    const { data } = await httpClient.post(
      "/v1b/user/request_email_verification",
      null,
      {
        params: { email },
      }
    );
    return data;
  });
}

export function useVerifyEmail() {
  const httpClient = useHttpClient(api_v_url);
  const queryClient = useQueryClient();

  return useMutation(
    async ({ email, code }) => {
      const { data } = await httpClient.post("/v1b/user/verify_email", null, {
        params: { email, code },
      });
      return data;
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.USER);
      },
    }
  );
}

// 이미지를 base64로 변환하는 유틸 함수
const convertImageToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
